import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { DataGrid } from '@mui/x-data-grid';

import { formatNumberToCurrency } from '../../../utils/currency';
import {
  addPlanProduct,
  changePlanProductStatus,
  getCateringProductPlans
} from '../../../api/catering';
import empty from '../../../assets/images/empty_search.png';
import ReactQuill from 'react-quill';
import { renderRequired } from '../emptyData';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const CATERING_SETTINGS_MENU = {
  INFO: 0,
  MENU_MANAGEMENT: 1
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  p: 4,
  width: 500,
  background: '#FFFFFF',
  borderRadius: '10px',
  outline: 'none'
};

const tableColumns = [
  {
    field: 'name',
    headerName: 'Menu',
    width: 200,
    align: 'left'
  },
  {
    field: 'defaultPrice',
    headerName: 'Price',
    type: 'number',
    width: 100,
    valueGetter: (params) => formatNumberToCurrency(params.row.defaultPrice)
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    align: 'left',
    valueGetter: (params) => (params.row.status === 1 ? 'Active' : 'Nonactive')
  }
];

const EditPlan = ({
  dataType,
  onBack,
  selectedPlan,
  menuId,
  snackbarAction,
  getSchedulesPlans,
  plans
}) => {
  const [type, setType] = useState([menuId]);
  const [banner, setBanner] = useState(selectedPlan.imageUrl);
  const [menuActive, setMenuActive] = useState(CATERING_SETTINGS_MENU.INFO);
  const [modal, setModal] = useState(false);

  const [productPlans, setProductPlans] = useState([]);
  const [filteredProductPlans, setFilteredProductPlans] = useState([]);

  const [selectedProductPlans, setSelectedProductPlans] = useState([]);

  const { id } = selectedPlan;

  const {
    finalPrice,
    name,
    originalPrice,
    termAndConditionHtml,
    usageQuantity,
    cateringPlanProducts
  } = plans.find((data) => data.id === id);

  const fetchProductPlans = async () => {
    const { data } = await getCateringProductPlans();
    formattedResponseProductPlans(data);
  };

  const formattedResponseProductPlans = (data) => {
    const tempContainer = [];

    for (const item of data) {
      const findSameProducts = cateringPlanProducts?.find((items) => items.id === item.id);
      if (!findSameProducts) {
        tempContainer.push(item);
      }
    }
    setProductPlans(tempContainer);
    setFilteredProductPlans(tempContainer);
  };

  const validateFinalPrice = (price) => {
    return price < getValues('priceBefore');
  };

  const validateBeforeDiscountPrice = (price) => {
    return price > getValues('price');
  };

  useEffect(() => {
    fetchProductPlans();
  }, []);

  const handleAddFood = async () => {
    try {
      const formattedSelectedPlans = [];
      selectedProductPlans?.length > 0 &&
        selectedProductPlans?.map((data) => {
          formattedSelectedPlans.push({
            id: data,
            limit: null
          });
        });
      await addPlanProduct(id, formattedSelectedPlans);
      getSchedulesPlans();
      snackbarAction.returnSuccess();
      setModal(false);
    } catch (error) {
      snackbarAction.returnError();
    }
  };

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: name,
      usage_quantity: usageQuantity,
      price: finalPrice,
      priceBefore: originalPrice,
      terms_and_conditions: termAndConditionHtml,
      types: [menuId]
    }
  });

  const handleSearchFieldChange = (event) => {
    setFilteredProductPlans(
      productPlans.filter((plan) =>
        new RegExp(`${event.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}`, 'i').test(plan.name)
      )
    );
  };

  const DropzoneImage = () => {
    const [isImageUploaded, setIsImageUploaded] = useState(false);

    const onDrop = useCallback((acceptedFiles) => {
      const data = new FormData();
      data.append('image', acceptedFiles[0]);

      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/caterings/plans/image`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
        })
        .then((res) => {
          const {
            data: {
              data: { image_url }
            }
          } = res;
          setBanner(image_url);
        });
      setIsImageUploaded(true);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
      <>
        <div
          {...getRootProps()}
          style={{
            border: '1px solid #CCCCCC',
            borderRadius: 8,
            width: '100%',
            height: '100%'
          }}>
          <input {...getInputProps()} />
          {banner !== null && (
            <img
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
                borderRadius: 8
              }}
              src={banner}
              alt="image"
            />
          )}
        </div>
      </>
    );
  };

  const onSubmit = async (data) => {
    const formData = new FormData();

    const { name, price, priceBefore, usage_quantity, types, terms_and_conditions } = data;

    types.forEach((item) => {
      formData.append('types[]', item);
    });

    formData.append('name', name);
    formData.append('image_url', banner);
    formData.append('final_price', price);
    formData.append('original_price', priceBefore);
    formData.append('usage_quantity', usage_quantity);
    formData.append('terms_and_conditions', terms_and_conditions);

    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/caterings/plans/${id}/edit`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
        })
        .then(() => {
          snackbarAction.returnSuccess();
          setTimeout(onBack(), 3000);
        });
    } catch (e) {
      snackbarAction.returnError();
    }
  };

  const ProductsGrid = () => (
    <Grid container sx={{ overflow: 'auto', p: 1 }} spacing={2}>
      {cateringPlanProducts?.length > 0 &&
        cateringPlanProducts?.map((item, index) => (
          <Grid item xs={6} lg={3} md={4} key={index}>
            <ProductItem item={item} />
          </Grid>
        ))}
      {cateringPlanProducts?.length === 0 && (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 5 }}>
          <Grid item xs={3}>
            <img src={empty} alt="empty" />
          </Grid>
        </Grid>
      )}
    </Grid>
  );

  const ProductItem = ({ item }) => {
    const { id, cateringPlanId, limit, product, productId, status } = item;
    const { description, imageUrl, name } = product;
    const isAvailable = status === 1;

    const [checked, setChecked] = useState(isAvailable);

    const handleChangeStatus = async (event) => {
      setChecked(event?.target?.checked);
      await changePlanProductStatus(id, event?.target?.checked ? '1' : '0');
    };

    return (
      <Paper
        sx={{
          p: 1,
          pb: 1.75,
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }}>
        <img
          src={imageUrl}
          style={{
            width: '100%',
            borderRadius: 10,
            objectFit: 'cover'
          }}
          alt=""
        />
        <Box sx={{ flex: 1, mt: 1 }}>
          <Typography
            sx={{
              fontWeight: 'bold',
              textTransform: 'uppercase'
            }}
            variant="p">
            {name}
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 1.375,
            mx: 0.5,
            display: 'flex',
            alignItems: 'center'
          }}>
          <Box sx={{ flex: 1 }}>
            {checked === false && (
              <Typography color="error" variant="caption" sx={{ fontWeight: 'bold' }}>
                Unavailable
              </Typography>
            )}
          </Box>
          <Switch checked={checked} onChange={handleChangeStatus} />
        </Box>
      </Paper>
    );
  };

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    const typeSelected = typeof value === 'string' ? value.split(',') : value;
    setType(typeSelected);
    setValue('types', typeSelected);
  };

  const isTabActive = (tab) => (tab === menuActive ? 'tab-menu-active' : '');

  return (
    <>
      <div className="display-flex" style={{ cursor: 'pointer' }} onClick={() => onBack()}>
        <ArrowBackIos />
        <span style={{ fontSize: '16px' }}>Edit Plan</span>
      </div>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card sx={{ mt: 2, border: 'none', boxShadow: 'none' }}>
            <CardContent>
              <Typography variant="h5" sx={{ color: '#252733', fontWeight: 700 }}>
                Catering Settings
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card sx={{ border: 'none', boxShadow: 'none', padding: 0 }}>
            <CardContent sx={{ padding: 0 }}>
              <div
                className={`display-flex-space-between cursor-pointer tab-menu ${isTabActive(
                  CATERING_SETTINGS_MENU.INFO
                )}`}
                onClick={() => setMenuActive(CATERING_SETTINGS_MENU.INFO)}>
                <div>
                  <p style={{ fontSize: '16px', margin: 0 }}> Info </p>
                  <p style={{ fontSize: '12px', margin: 0 }}>Upload title & banner for catering</p>
                </div>
                <ArrowForwardIos
                  sx={{
                    fontSize: '25px',
                    marginTop: '5px',
                    cursor: 'pointer'
                  }}
                />
              </div>
              <div
                className={`display-flex-space-between cursor-pointer tab-menu ${isTabActive(
                  CATERING_SETTINGS_MENU.MENU_MANAGEMENT
                )}`}
                style={{ marginTop: 10 }}
                onClick={() => setMenuActive(CATERING_SETTINGS_MENU.MENU_MANAGEMENT)}>
                <div>
                  <p style={{ fontSize: '16px', margin: 0 }}>Menu Management</p>
                  <p style={{ fontSize: '12px', margin: 0 }}>
                    Add selected menu to become menu selection at catering
                  </p>
                </div>
                <ArrowForwardIos
                  sx={{
                    fontSize: '25px',
                    marginTop: '5px',
                    cursor: 'pointer'
                  }}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>

        {menuActive === CATERING_SETTINGS_MENU.INFO && (
          <Grid item xs={9}>
            <Card
              sx={{
                border: 'none',
                boxShadow: 'none'
              }}>
              <CardContent>
                <Controller
                  name={'name'}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <div style={{ marginBottom: '2vh' }}>
                      <p className="form-label">Title</p>
                      <TextField
                        error={errors?.name}
                        onChange={onChange}
                        value={value}
                        placeholder="Example: Paket 3 hari bersama"
                        sx={{ width: '100%' }}
                        size="small"
                      />
                      {errors?.name && renderRequired()}
                    </div>
                  )}
                />

                <p className="form-label" style={{ marginBottom: '1.5vh' }}>
                  Upload Banner
                </p>
                <Grid container spacing={{ xs: 2, md: 3 }} sx={{ overflow: 'auto' }}>
                  <Grid item xs={6} sm={6} md={6}>
                    <DropzoneImage />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <p>Upload banner</p>
                    <ul>
                      <li>
                        Format file JPG, JPEG, PNG <br /> (Rasio 2:1, min. 360 x 360px, maks. 2MB).
                      </li>
                      <li>Rasio bidang safe area 1:2,5.</li>
                    </ul>
                  </Grid>
                </Grid>
                <div className="display-flex" style={{ margin: '1vh 0' }}>
                  <div>
                    <Controller
                      name={'price'}
                      control={control}
                      rules={{
                        required: true,
                        min: 1,
                        validate: validateFinalPrice
                      }}
                      render={({ field: { onChange, value } }) => (
                        <div>
                          <p className="form-label">Final Price</p>
                          <TextField
                            error={errors?.price}
                            onChange={onChange}
                            value={value}
                            placeholder="Catering Price"
                            sx={{ width: '100%' }}
                            size="small"
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                          {errors?.price &&
                            renderRequired(
                              errors?.price?.type === 'validate'
                                ? 'Final price should be lower'
                                : 'required'
                            )}
                        </div>
                      )}
                    />
                  </div>
                  <div style={{ marginLeft: '1vw' }}>
                    <Controller
                      name={'priceBefore'}
                      control={control}
                      rules={{
                        required: true,
                        min: 1,
                        validate: validateBeforeDiscountPrice
                      }}
                      render={({ field: { onChange, value } }) => (
                        <div>
                          <p className="form-label">Price Before Discount</p>
                          <TextField
                            error={errors?.priceBefore}
                            onChange={onChange}
                            value={value}
                            placeholder="Catering Price"
                            sx={{ width: '100%' }}
                            size="small"
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                          {errors?.priceBefore &&
                            renderRequired(
                              errors?.priceBefore?.type === 'validate'
                                ? 'Before price should be higher'
                                : 'required'
                            )}
                        </div>
                      )}
                    />
                  </div>
                </div>

                <div className="display-flex" style={{ margin: '1vh 0' }}>
                  <div>
                    <Controller
                      name={'usage_quantity'}
                      control={control}
                      rules={{ required: true, min: 1 }}
                      render={({ field: { onChange, value } }) => (
                        <div>
                          <p className="form-label">Number of Food</p>
                          <TextField
                            error={errors?.usage_quantity}
                            onChange={onChange}
                            value={value}
                            placeholder="Number of Food"
                            sx={{ width: '100%' }}
                            size="small"
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                          {errors?.usage_quantity && renderRequired()}
                        </div>
                      )}
                    />
                  </div>
                  <FormControl sx={{ width: '31%', ml: 2 }} size="small">
                    <p className="form-label">Schedule</p>
                    <Select
                      multiple
                      displayEmpty
                      value={type}
                      onChange={handleChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => {
                        const tempSelected = [];
                        selected?.map((item) =>
                          tempSelected.push(dataType?.find((x) => x.id === item).name)
                        );
                        if (selected.length === 0) {
                          return <em>Select One</em>;
                        }
                        return tempSelected.join(', ');
                      }}
                      MenuProps={MenuProps}
                      inputProps={{ 'aria-label': 'Without label' }}
                      placeholder="">
                      <MenuItem disabled value="">
                        <em>Schedule</em>
                      </MenuItem>
                      {dataType.map((item) => (
                        <MenuItem key={item?.name} value={item?.id}>
                          <Checkbox checked={type.indexOf(item?.id) > -1} />
                          <ListItemText primary={item?.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div style={{ marginBottom: '20px' }}>
                  <Controller
                    name={'terms_and_conditions'}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <p
                          style={{
                            fontSize: '16px',
                            margin: '15px 0 1vh 0',
                            fontWeight: 700
                          }}>
                          Terms & Condition
                        </p>
                        <ReactQuill value={value} theme="snow" onChange={onChange} />
                        {errors?.terms_and_conditions && renderRequired()}
                      </>
                    )}
                  />
                </div>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ width: '15%' }}
                  onClick={handleSubmit(onSubmit)}>
                  Save
                </Button>
              </CardContent>
            </Card>
          </Grid>
        )}

        {menuActive === CATERING_SETTINGS_MENU.MENU_MANAGEMENT && (
          <Grid item xs={9}>
            <Card
              sx={{
                border: 'none',
                boxShadow: 'none'
              }}>
              <CardContent>
                <div className="display-flex-space-between">
                  <p style={{ fontSize: '16px', margin: 0 }}>Menu Management</p>
                  <Button
                    variant="outlined"
                    size="medium"
                    sx={{ width: '15%' }}
                    onClick={() => {
                      setModal(true);
                    }}>
                    Add Food
                  </Button>
                </div>
                <ProductsGrid />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>

      <Modal
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className="display-flex-space-between" style={{ marginBottom: '2vh' }}>
            <Typography variant="h6" sx={{ pt: 1, fontWeight: 700 }}>
              Add Foods
            </Typography>
            <TextField
              size="small"
              placeholder="Search"
              sx={{ width: '40%' }}
              onChange={(event) => handleSearchFieldChange(event?.target?.value)}
            />
          </div>

          {productPlans?.length > 0 && (
            <>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={filteredProductPlans}
                  columns={tableColumns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                  onSelectionModelChange={(e) => setSelectedProductPlans(e)}
                />
              </div>
              <Button
                variant="contained"
                size="medium"
                sx={{ mt: 2, width: '100%' }}
                onClick={handleAddFood}>
                Save
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default EditPlan;
