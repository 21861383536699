import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import styled from 'styled-components';

import '../assets/style.css';
import { DrawerHeader, HeaderBar } from './shared';
import { getCateringSchedules } from '../api/catering';
import { createCalendarList } from '../utils/time';
import CalendarStack from './shared/Catering/CalendarStack';
import FoodBatchTab from './shared/Catering/TabContent/FoodBatchTab';
import OrdersTab from './shared/Catering/TabContent/OrdersTab';
import PackedTab from './shared/Catering/TabContent/PackedTab';
import dayjs from 'dayjs';

const tab = {
  FOOD_BATCH: 1,
  ORDERS: 2,
  PACKED: 3
};

const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2vh;
`;

const TabContentContainer = styled.div`
  background-color: #ffffff;
  padding: 1.5rem;
  min-height: 50vh;
`;

const calendarList = createCalendarList();
const currentDate = calendarList[0]?.date;

const Catering = ({ isNavigationDrawerOpen, onMenuButtonClick, snackbarAction }) => {
  const [tabActive, setTabActive] = useState(tab.FOOD_BATCH);
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [calendarListIndex, setCalendarListIndex] = useState(0);
  const [selectedType, setSelectedType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const moveBehind = async () => {
    if (calendarListIndex !== 0) {
      let newIndex = calendarListIndex - 1;
      setCalendarListIndex(newIndex);
      await handleCalendarChange(calendarList[newIndex].date);
    }
  };

  const moveAhead = async () => {
    let newIndex = calendarListIndex + 1;
    setCalendarListIndex(newIndex);
    await handleCalendarChange(calendarList[newIndex].date);
  };

  const isTabActive = (tab) => (tabActive === tab ? 'tab-active' : '');

  const handleCalendarChange = async (date) => {
    if (selectedDate != date) setSelectedType(null);
    setSelectedDate(date);
  };

  return (
    <>
      <HeaderBar
        isNavigationDrawerOpen={isNavigationDrawerOpen}
        onMenuButtonClick={onMenuButtonClick}
        title="Overview Catering"
        selectedDate={selectedDate}
        isProductType={true}
        setIsLoading={setIsLoading}
        changeType={(e) => {
          setSelectedType(e);
          setIsLoading(true);
        }}
      />
      <Box
        component="main"
        className="h-scroll"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          flexGrow: 1,
          overflow: 'scroll'
        }}>
        <DrawerHeader />
        <CalendarStack
          moveAhead={moveAhead}
          moveBehind={moveBehind}
          selectedDate={selectedDate}
          value={0}
          onClick={handleCalendarChange}
          calendarList={calendarList}
        />

        <Container>
          <TabContainer>
            <div
              className={`tab ${isTabActive(tab.FOOD_BATCH)}`}
              onClick={() => {
                setTabActive(tab.FOOD_BATCH);
              }}>
              Food Batch
            </div>
            <div
              className={`tab ${isTabActive(tab.ORDERS)}`}
              onClick={() => {
                setTabActive(tab.ORDERS);
              }}>
              Orders
            </div>
            <div
              className={`tab ${isTabActive(tab.PACKED)}`}
              onClick={() => {
                setTabActive(tab.PACKED);
              }}>
              Packed
            </div>
          </TabContainer>

          <TabContentContainer>
            {tabActive === tab.FOOD_BATCH && selectedType !== null && (
              <FoodBatchTab
                date={selectedDate}
                selectedType={selectedType}
                snackbarAction={snackbarAction}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            )}

            {tabActive === tab.ORDERS && selectedType && (
              <OrdersTab
                date={selectedDate}
                selectedType={selectedType}
                snackbarAction={snackbarAction}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            )}

            {tabActive === tab.PACKED && selectedType && (
              <PackedTab
                date={selectedDate}
                selectedType={selectedType}
                snackbarAction={snackbarAction}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            )}
          </TabContentContainer>
        </Container>
      </Box>
    </>
  );
};

export default Catering;
