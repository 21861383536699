import { kitchenAxios } from '../utils/api';

export const getAllTables = () => kitchenAxios.get('tables');

export const getTableDetail = (id) => kitchenAxios.get(`tables/${id}`);

export const answerCall = (tableId) =>
  kitchenAxios.post(`tables/${tableId}/answer-call`);

export const setTableFree = (tableId) =>
  kitchenAxios.post(`tables/${tableId}/free`);
