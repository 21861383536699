import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Input,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { cancelReserved, getReservation, getSupervisorOtp2, makeReserved, makeVerify } from '../api/reservation';
import { HeaderBar } from './shared';
import CachedIcon from '@mui/icons-material/Cached';
import debounce from 'lodash.debounce';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { getSupervisorOtp, submitDownPayment } from '../api/kantong';
import { formatNumberToCurrency } from '../utils/currency';

const TableReservation = ({ isNavigationDrawerOpen, onMenuButtonClick,snackbarAction }) => {
  const [allReservation, setAllReservation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleAddDownPayment = (data) => {
    setIsOpenDownPayment(true);
    setDetailData(data);
  }
  

  const fectReservationApi = async () => {
    
    
    setIsLoading(true);
    try {
      const { data } = await getReservation();
 
      setAllReservation(data);
      setIsError(false);
    } catch (error) {
      console.error();
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    fectReservationApi();
  }, []);



  const [isHandleLoading, setIsHandleLoading] = useState(false);
  const [isOpenNotif, setIsOpenNotif] = useState(false);
  const [isDeclineOpenNotif, setIsDeclineOpenNotif] = useState(false);
  const [isVerifyOpenNotif, setIsVerifyOpenNotif] = useState(false);

  const [detailData, setDetailData] = useState();
  const [isOpenReserve, setIsOpenReserve] = useState(false);
  const [isOpenDecline, setIsOpenDecline] = useState(false);
  const [isOpenVerify, setIsOpenVerify] = useState(false);
  const [isOpenDownPayment,setIsOpenDownPayment] = useState(false);
  const [supervisorPhone, setSupervisorPhone] = useState("");
  const [additionalDownPayment, setAdditionalDownPayment] = useState(0);
  const [supervisorOTP, setSupervisorOTP] = useState("")
  const [isOtpRequest, setIsOTPRequest] = useState(false)
  const [isAnyActivity, setIsAnyActivity] = useState(false);


  const handleReserved = (data) => {
    setDetailData(data);
    setIsOpenReserve(true);
    doActivity();
  };
  const handleDecline = (data) => {
    setDetailData(data);
    setIsOpenDecline(true);
    doActivity();
  };
  const handleVerify = (data) => {
    setDetailData(data);
    setIsOpenVerify(true);
    doActivity();
  };

  const handleReservedFix = () => {
    const fetchReservaed = async () => {
      setIsHandleLoading(true);
      try {
        await makeReserved(detailData?.reservations?.id);
        setIsOpenNotif(true);
        setIsOpenReserve(false);
      } catch (error) {
        console.error(error);
      } finally {
        setIsHandleLoading(false);
        fectReservationApi();
      }
    };
    fetchReservaed();
    doActivity();
  };
  

  const handleDeclineFix = () => {
    const fetchReservaed = async () => {
      setIsHandleLoading(true);
      try {
        await cancelReserved(detailData?.reservations?.id);
        setIsDeclineOpenNotif(true);
        setIsOpenDecline(false);
      } catch (error) {
        console.error(error);
      } finally {
        setIsHandleLoading(false);
        fectReservationApi();
      }
    };
    fetchReservaed();
    doActivity();
  };

  const handleVerifyFix = (data) => {
    const fetchVerify = async () => {
      setIsHandleLoading(true);
      try {
        await makeVerify(detailData?.reservations?.id);
        setIsVerifyOpenNotif(true);
        setIsOpenVerify(false);
      } catch (error) {
        console.error(error);
      } finally {
        setIsHandleLoading(false);
        fectReservationApi();
      }
    };

    fetchVerify();
    doActivity();
  };

  const handleGetOTP = async () => {
    try {
      setIsOTPRequest(true)
      await getSupervisorOtp(supervisorPhone)
      return snackbarAction.returnSuccess('OTP berhasil di kirim');
    }
    catch (error) 
    {
      return snackbarAction.returnError(error?.response?.data?.meta?.message)
    }
  }

  const handleSubmitDownPayment = async () => {
    const data = {
      'user_id' : detailData?.id ?? 0,
      'invoice_id': detailData?.reservations?.invoiceReservationId,
      'otp' : supervisorOTP,
      'phone_number' : supervisorPhone,
      'amount' : additionalDownPayment
    }

    try {
      await submitDownPayment(data)
      return snackbarAction.returnSuccess('Down payment berhasil di di tambahkan');
    }
    catch(error)
    {
      return snackbarAction.returnError(error?.meta?.message)
    }
    finally {
      setIsOpenDownPayment(false);
      fectReservationApi()
      setAdditionalDownPayment(0)
    }
  }

  

  // !---------------- Activity ---------------------------


  const activityDebounce = useRef(
    debounce(() => {
      setIsAnyActivity(true);
    }, 5000)
  ).current;

  const doActivity = () => {
    setIsAnyActivity(false);
    activityDebounce();
  };

  useEffect(() => {
    doActivity();
  }, []);

  useEffect(() => {
    if (isAnyActivity) {
      const interval = setInterval(() => {
        fectReservationApi();
      }, 90000);

      return () => clearInterval(interval);
    }
  }, [isAnyActivity]);

  return (
    <>
      <HeaderBar
        isNavigationDrawerOpen={isNavigationDrawerOpen}
        onMenuButtonClick={onMenuButtonClick}
        title={`Table Reservation`}
      />
      

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isOpenNotif}
        onClose={() => setIsOpenNotif(false)}>
        <Alert
          severity="success"
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isOpenNotif}
          onClose={() => setIsOpenNotif(false)}>
          Success reserved
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isDeclineOpenNotif}
        onClose={() => setIsDeclineOpenNotif(false)}>
        <Alert
          severity="success"
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isOpenNotif}
          onClose={() => setIsDeclineOpenNotif(false)}>
          Success decline
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isVerifyOpenNotif}
        onClose={() => setIsVerifyOpenNotif(false)}>
        <Alert
          severity="success"
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isOpenNotif}
          onClose={() => setIsVerifyOpenNotif(false)}>
          Success verify
        </Alert>
      </Snackbar>

      <Dialog
        open={isOpenReserve}
        onClose={() => {
          setIsOpenReserve(false);
          setDetailData();
        }}>
        <DialogTitle>Reserve {detailData?.reservations?.table}</DialogTitle>
        <DialogContent dividers>
          <Typography sx={{ mb: '20px' }}>Are you sure to approve this reservation ?</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
              <Typography>Name</Typography>
              <Typography>{detailData?.name || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Telepon</Typography>
              <Typography>{detailData?.telpon || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Room</Typography>
              <Typography>{detailData?.reservations?.room || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Table</Typography>
              <Typography>{detailData?.reservations?.table || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Person</Typography>
              <Typography>{detailData?.reservations?.numberOfPersons || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Payment</Typography>
              <Typography>
                {detailData?.reservations?.paymentMethod || '-'} - Rp.
                {detailData?.reservations?.amount || '-'}{' '}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Note</Typography>
              <Box>
                <Typography>{detailData?.reservations?.note.split('-')[0] || '-'}</Typography>
                <Typography>{detailData?.reservations?.note.split('-')[1] || '-'}</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              gap: 0.4,
              mt: '20px'
            }}>
            <Button
              color="error"
              onClick={() => {
                setIsOpenReserve(false);
                setDetailData();
              }}>
              Cancel
            </Button>
            <Button color="success" variant="contained" onClick={handleReservedFix}>
              Reserve
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isOpenDecline}
        onClose={() => {
          setIsOpenDecline(false);
          setDetailData();
        }}>
        <DialogTitle>Decline {detailData?.reservations?.table}</DialogTitle>
        <DialogContent dividers>
          <Typography sx={{ mb: '20px' }}>Are you sure to decline this reservation ?</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
              <Typography>Name</Typography>
              <Typography>{detailData?.name || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Telepon</Typography>
              <Typography>{detailData?.telpon || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Room</Typography>
              <Typography>{detailData?.reservations?.room || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Table</Typography>
              <Typography>{detailData?.reservations?.table || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Person</Typography>
              <Typography>{detailData?.reservations?.numberOfPersons || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Note</Typography>
              <Typography>{detailData?.reservations?.note || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Payment</Typography>
              <Typography>
                {detailData?.reservations?.paymentMethod || '-'} - Rp.
                {detailData?.reservations?.amount || '-'}{' '}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              gap: 0.4,
              mt: '20px'
            }}>
            <Button
              color="error"
              onClick={() => {
                setIsOpenDecline(false);
                setDetailData();
              }}>
              Cancel
            </Button>
            <Button color="error" variant="contained" onClick={handleDeclineFix}>
              Decline
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isOpenVerify}
        onClose={() => {
          setIsOpenVerify(false);
          setDetailData();
        }}>
        <DialogTitle>Verify {detailData?.reservations?.table}</DialogTitle>
        <DialogContent dividers>
          <Typography sx={{ mb: '20px' }}>Are you sure to confirm this reservation ?</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
              <Typography>Name</Typography>
              <Typography>{detailData?.name || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Telepon</Typography>
              <Typography>{detailData?.telpon || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Room</Typography>
              <Typography>{detailData?.reservations?.room || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Table</Typography>
              <Typography>{detailData?.reservations?.table || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Person</Typography>
              <Typography>{detailData?.reservations?.numberOfPersons || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Note</Typography>
              <Typography>{detailData?.reservations?.note || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Payment</Typography>
              <Typography>
                {detailData?.reservations?.paymentMethod || '-'} - Rp.
                {detailData?.reservations?.amount || '-'}{' '}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              gap: 0.4,
              mt: '20px'
            }}>
            <Button
              color="error"
              onClick={() => {
                setIsOpenVerify(false);
                setDetailData();
              }}>
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={handleVerifyFix}>
              Verify
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isOpenDownPayment}
        onClose={() => {
          setIsOpenDownPayment(false);
          setDetailData();
        }}>
        <DialogTitle>Add Down Payment</DialogTitle>
        <DialogContent dividers>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
              <Typography>Name</Typography>
              <Typography>{detailData?.name || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Telepon</Typography>
              <Typography>{detailData?.telpon || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Room</Typography>
              <Typography>{detailData?.reservations?.room || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Table</Typography>
              <Typography>{detailData?.reservations?.table || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Person</Typography>
              <Typography>{detailData?.reservations?.numberOfPersons || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Note</Typography>
              <Typography>{detailData?.reservations?.note || '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Payment</Typography>
              <Typography>
                {detailData?.reservations?.paymentMethod || '-'} {` - `} 
                {formatNumberToCurrency(detailData?.reservations?.amount) || '-'}{' '}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography color={'#2E7D32'}>Tambahan Down Payment</Typography>
              <Typography color={'#2E7D32'}>
                {formatNumberToCurrency(additionalDownPayment)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop:2,
                gap:2,
              }}>
              <Input  value={supervisorPhone} onChange={(e) => {setSupervisorPhone(e.target.value); isOtpRequest && setIsOTPRequest(false)}} placeholder='Nomor atasan'></Input>
              <Button variant='contained' disabled={!supervisorPhone || isOtpRequest } style={{padding:6}} onClick={handleGetOTP}>Get OTP</Button>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',gap:2 }}>
             <Input style={{width:'150px'}} onChange={(e) => setSupervisorOTP(e.target.value)} placeholder='Masukkan OTP'></Input>
             <Input style={{width:'150px'}} onChange={(e) => setAdditionalDownPayment(e.target.value)} placeholder='Masukkan Jumlah'></Input>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              gap: 0.4,
              mt: '20px'
            }}>
            <Button
              color="error"
              onClick={() => {
                setIsOpenDownPayment(false);
              }}>
              Cancel
            </Button>
            <Button color="primary" disabled={!supervisorPhone || !supervisorOTP || !additionalDownPayment || !isOtpRequest} variant="contained" onClick={handleSubmitDownPayment}>
              Submit
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Box sx={{ mt: '40px', width: '100%', p: '40px', position: 'relative' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button
            sx={{ mb: '10px' }}
            onClick={() => {
              fectReservationApi();
              doActivity();
            }}>
            <CachedIcon />
          </Button>
          {/* <Box>
            {isAnyActivity ? (
              <SentimentSatisfiedIcon sx={{ color: 'red' }} />
            ) : (
              <TagFacesIcon sx={{ color: 'green' }} />
            )}
          </Box> */}
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead
              sx={{ bgcolor: '#363740', color: 'white' }}
              stickyHeader
              aria-label="sticky table">
              <TableRow>
                <TableCell sx={{ color: 'white', width: '54px' }}>No.</TableCell>
                <TableCell sx={{ color: 'white' }}>Table</TableCell>
                <TableCell sx={{ color: 'white' }}>Room</TableCell>
                <TableCell sx={{ color: 'white' }}>Name</TableCell>
                <TableCell sx={{ color: 'white' }}>Telepon</TableCell>
                <TableCell sx={{ color: 'white' }}>Time</TableCell>
                <TableCell sx={{ color: 'white' }}>Payment</TableCell>
                <TableCell sx={{ color: 'white' }}>Person</TableCell>
                <TableCell sx={{ color: 'white' }}>Act</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allReservation.map((table, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ width: '54px' }}>{index + 1}</TableCell>
                  <TableCell>{table?.reservations?.table}</TableCell>
                  <TableCell>{table?.reservations?.room}</TableCell>
                  <TableCell>{table?.name}</TableCell>
                  <TableCell>{table?.telpon}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <Typography>
                        {dayjs(table?.reservations?.appointmentAt).format('DD/MMM/YY')}
                      </Typography>
                      <Typography sx={{ color: 'blue' }}>
                        {dayjs(table?.reservations?.appointmentAt).format('hh:mm')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                   <Box style={{display:'flex', alignItems:'center', gap:5}} >
                    {table?.orders?.length < 1 && table?.reservations?.statusPayment !== 100 && <Box onClick={() => handleAddDownPayment(table)} display={'flex'} alignItems={'center'}>
                      <AddCircleRoundedIcon style={{cursor:'pointer', color:'#2E7D32'}}/>
                    </Box>}
                      {table?.reservations?.paymentMethod} - {formatNumberToCurrency(table?.reservations?.amount)}
                   </Box>
                  </TableCell>

                  <TableCell>{table?.reservations?.numberOfPersons}</TableCell>
                  <TableCell sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {table?.reservations?.status === 100 &&
                      table?.reservations?.statusPayment === 200 && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          {/* <Button
                            color="error"
                            variant="contained"
                            onClick={() => handleDecline(table)}>
                            Decline
                          </Button> */}
                          <Button
                            color="success"
                            variant="contained"
                            onClick={() => handleReserved(table)}>
                            Reserve
                          </Button>
                        </Box>
                      )}
                    {table?.reservations?.status === 200 &&
                      table?.reservations?.statusPayment === 200 && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <Typography sx={{ color: '#2E7D32' }}>Reserved || </Typography>
                          <Button variant="contained" onClick={() => handleVerify(table)}>
                            Verify
                          </Button>
                        </Box>
                      )}
                    {table?.reservations?.status === 401 &&
                      table?.reservations?.statusPayment !== 100 && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <Typography sx={{ color: '#1976D2' }}>Reserved || Verified </Typography>
                        </Box>
                      )}
                    {table?.reservations?.status === 500 && (
                      <Typography sx={{ color: '#D32F2F' }}>Declined</Typography>
                    )}
                    {table?.reservations?.statusPayment === 100 && (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        <Typography sx={{ color: '#D32F2F' }}>Not Paid || </Typography>
                        <Button
                          color="error"
                          variant="contained"
                          onClick={() => handleDecline(table)}>
                          Decline
                        </Button>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {isLoading && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                height: '80px',
                alignItems: 'center'
              }}>
              <CircularProgress />
            </Box>
          )}
        </TableContainer>
      </Box>
      {isHandleLoading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: 'white',
            opacity: 0.5
          }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};
export default TableReservation;
