import { Box, TableCell, TableSortLabel, Typography } from '@mui/material';
import { ArrowDownIcon, ArrowPaleIcon, ArrowUpIcon } from '../../../../assets';

const SortableTableHeader = ({
  label,
  name,
  sort,
  direction,
  handleHeaderOnClick,
  icon,
  summary,
  sortable,
  info
}) => {
  const newDirection = direction === 'asc' ? 'desc' : 'asc';
  return (
    <TableCell
      align={'left'}
      sortDirection={direction}
      sx={{ paddingLeft: '14px', paddingRight: '5px', border: '1px solid #C8C8CC' }}>
      <Box
        onClick={() => (sortable ? handleHeaderOnClick(name, newDirection) : {})}
        sx={{
          display: 'flex',
          gap: '2px',
          cursor: 'pointer',
          alignItems: 'center',
          fontSize: '13px',
          fontWeight: 'bold'
        }}>
        {icon}
        {label}
        {info !== null && info !== undefined ? <>{info}</> : ''}
        {summary !== null && summary !== undefined ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '8px',
                background: '#363740',
                color: 'white',
                borderRadius: '50%',
                height: '16px',
                width: '16px'
              }}>
              {summary}
            </Box>
          </Box>
        ) : (
          ''
        )}
        {sortable && (
          <Box style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
            {sort === name ? (
              direction === 'asc' ? (
                <ArrowUpIcon />
              ) : (
                <ArrowDownIcon />
              )
            ) : (
              <ArrowPaleIcon />
            )}
          </Box>
        )}
      </Box>
    </TableCell>
  );
};

export default SortableTableHeader;
