/* eslint-disable jsx-a11y/alt-text */
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { getProductsByCategory, getCategory } from '../api/products';
import { formatNumberToCurrency } from '../utils/currency';
import { DrawerHeader, HeaderBar } from './shared';
import { changeProductStatus } from '../api/products';

const IS_AVAILABLE_STATUS = 1;

const BackButton = () => (
  <Button
    startIcon={<ArrowBackIcon />}
    to="/menu"
    component={RouterLink}
    sx={{
      width: '12rem',
      ml: 4.625,
      mt: 6.5,
    }}
  >
    Back to Category
  </Button>
);

const SearchField = ({ onChange }) => (
  <TextField
    placeholder="Search"
    sx={{
      bgcolor: 'grey.200',
      width: '20rem',
      mt: 3.5,
      ml: 4.625,
    }}
    InputProps={{
      sx: {
        typography: 'h6',
        height: '3.125rem',
      },
    }}
    onChange={onChange}
  />
);

const ItemCard = ({ item }) => {
  const isAvailable = item.status === IS_AVAILABLE_STATUS;
  const [isChecked, setIsChecked] = useState(isAvailable);

  const handleSwitchChange = async () => {
    try {
      await changeProductStatus(item.id, isAvailable ? 0 : 1);
      setIsChecked((value) => !value);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Paper
      sx={{
        p: 1,
        pb: 1.75,
        height: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <img
        src={item.imageUrl}
        style={{
          width: '100%',
          borderRadius: 10,
          objectFit: 'cover',
        }}
      />
      <Box sx={{ flex: 1 }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            mt: 2.25,
            mx: 0.5,
            textTransform: 'uppercase',
          }}
        >
          {item.name}
        </Typography>
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold', mx: 0.5 }}
          color="grey.400"
        >
          {formatNumberToCurrency(item.price)}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 1.375,
          mx: 0.5,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box sx={{ flex: 1 }}>
          {!isChecked && (
            <Typography
              color="error"
              variant="caption"
              sx={{ fontWeight: 'bold' }}
            >
              Unavailable
            </Typography>
          )}
        </Box>
        <Switch
          defaultChecked={isAvailable}
          value={isChecked}
          onChange={handleSwitchChange}
        />
      </Box>
    </Paper>
  );
};

const ItemGrid = ({ items }) => (
  <Grid
    container
    spacing={1.5}
    sx={{ mt: 2, overflow: 'auto', pb: 4.625, px: 4.625 }}
  >
    {items.map((item) => (
      <Grid item xs={3} lg={2.4} key={item.id}>
        <ItemCard item={item} />
      </Grid>
    ))}
  </Grid>
);

const MenuDetail = ({ isNavigationDrawerOpen, onMenuButtonClick }) => {
  const { categoryId } = useParams();
  const { state } = useLocation();

  const [category, setCategory] = useState(state && state.category);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  const handleSearchFieldChange = (event) => {
    setFilteredItems(
      items.filter((item) =>
        new RegExp(
          `${event.target.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}`,
          'i'
        ).test(item.name)
      )
    );
  };

  useEffect(() => {
    if (category) return;

    const bootstrapAsync = async () => {
      try {
        const { data } = await getCategory({
          categoryId: parseInt(categoryId),
        });
        setCategory(data);
      } catch (error) {
        console.error(error);
      }
    };

    bootstrapAsync();
  }, [category, categoryId]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const { data } = await getProductsByCategory(parseInt(categoryId));
        setItems(data);
        setFilteredItems(data);
      } catch (error) {
        console.error(error);
      }
    };

    bootstrapAsync();
  }, [categoryId]);

  return (
    <>
      <HeaderBar
        isNavigationDrawerOpen={isNavigationDrawerOpen}
        onMenuButtonClick={onMenuButtonClick}
        title={`Menu - ${category ? category.name : ''}`}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <DrawerHeader />
        <BackButton />
        <SearchField onChange={handleSearchFieldChange} />
        <ItemGrid items={filteredItems} />
      </Box>
    </>
  );
};

const ITEM_TYPE = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  status: PropTypes.number.isRequired,
  imageUrl: PropTypes.string.isRequired,
}).isRequired;

SearchField.propTypes = { onChange: PropTypes.func.isRequired };

ItemCard.propTypes = { item: ITEM_TYPE };

ItemGrid.propTypes = { items: PropTypes.arrayOf(ITEM_TYPE).isRequired };

MenuDetail.propTypes = {
  isNavigationDrawerOpen: PropTypes.bool.isRequired,
  onMenuButtonClick: PropTypes.func.isRequired,
};

export default MenuDetail;
