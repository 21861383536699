import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';

import { getCateringsSchedulesOrders, handleCooked } from '../../../../api/catering';
import ModalContainer from '../ModalContainer';
import debounce from 'lodash.debounce';
import { GridSearchIcon } from '@mui/x-data-grid';
import {
  AddressIcon,
  CookedIcon,
  InProgressIcon,
  PackBlueIcon,
  PackIcon,
  PackageIcon
} from '../../../../assets';
import SortableTableHeader from './SortableTableHeader';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import dayjs from 'dayjs';

const OrdersTab = ({ date, selectedType, snackbarAction, isLoading, setIsLoading }) => {
  const [productsOrders, setProductsOrders] = useState(null);
  const [modalActive, setModalActive] = useState(false);
  const [selectedProductToViewRaw, setSelectedProductToViewRaw] = useState(null);
  const [search, setSearch] = useState('');
  const [orderKeyword, setOrderKeyword] = useState('');
  const [sort, setSort] = useState('name');
  const [direction, setDirection] = useState('asc');
  const [isUpdate, setIsUpdate] = useState(false);
  const [isBtnDisable, setIsButtonDisable] = useState(false);

  const nowDate = dayjs().format('YYYY-MM-DD');
  const showAction = date === nowDate;

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black
    }
  }));

  const menus = [
    { label: 'Name', name: 'name', sortable: true },
    { label: 'Package', name: 'package', icon: <PackageIcon />, sortable: true },
    { label: 'Address', name: 'address', icon: <AddressIcon />, sortable: true },
    {
      label: 'Orders',
      info: (
        <BootstrapTooltip title="If the text is bold and colored, the details of the note can be seen.">
          <InfoIcon sx={{ fontSize: 12 }} />
        </BootstrapTooltip>
      ),
      name: 'orders',
      icon: <CookedIcon />
    },
    {
      label: 'Chef',
      name: 'chef',
      icon: <InProgressIcon />
    }
  ];

  const handleHeaderOnClick = (name, direction) => {
    setSort(name);
    setDirection(direction);
  };

  const debounceOnChange = useRef(
    debounce((value) => {
      setOrderKeyword(value);
    }, 300)
  ).current;

  const getProductsOrders = async (newDate = date) => {
    try {
      const { data } = await getCateringsSchedulesOrders(
        selectedType?.id,
        newDate,
        orderKeyword,
        sort,
        direction
      );
      setProductsOrders(data);
    } catch (error) {
      snackbarAction.returnError();
    }
  };

  useEffect(async () => {
    setIsLoading(true);
    await getProductsOrders(date);
    setIsLoading(false);
  }, [date, selectedType, orderKeyword, sort, direction, isUpdate]);

  const handleOrdersPack = async (tempOrderStatuses) => {
    try {
      setIsButtonDisable(true);

      const payload = {
        order_status_ids: tempOrderStatuses
      };
      await handleCooked(payload);
      setModalActive(false);
      setIsUpdate((prev) => !prev);
      setIsButtonDisable(false);

      snackbarAction.returnSuccess();
    } catch (error) {
      setModalActive(false);
      snackbarAction.returnError();
      setIsButtonDisable(false);
    }
  };

  const ContentModalOrders = () => {
    const { orders } = selectedProductToViewRaw;

    const tempOrderStatuses = [];
    orders?.forEach((data) => {
      data?.orderStatuses?.forEach((item) => {
        tempOrderStatuses.push(item?.id);
      });
    });

    return (
      <Box sx={{ paddingX: '20px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Box
            sx={{
              display: 'flex',
              background:
                'linear-gradient(180deg, rgba(26, 114, 221, 0.2) -31.79%, rgba(26, 114, 221, 0) 100%),linear-gradient(0deg, #F7F7F7, #F7F7F7)',
              padding: '10px',
              borderRadius: '50%'
            }}>
            <PackBlueIcon />
          </Box>
          <Typography sx={{ fontWeight: 600 }}>Packing Details</Typography>
        </Box>
        <Typography fontSize={14} marginTop={2} marginBottom={2}>
          Are you sure you want to pack it? You can’t undo this action.
        </Typography>
        <Box display={'flex'} gap={1} justifyContent={'flex-end'}>
          <Button
            variant="text"
            color="error"
            onClick={() => setModalActive(false)}
            sx={{ margin: 0, paddingY: 0, fontSize: 12 }}>
            Cancel
          </Button>
          <Button
            sx={{
              margin: 0,
              paddingX: 0,
              paddingY: '5px',
              fontSize: 12,
              fontWeight: 300,
              borderRadius: '4px'
            }}
            variant="contained"
            onClick={() => handleOrdersPack(tempOrderStatuses)}
            disabled={!selectedProductToViewRaw?.isFinished || isBtnDisable}>
            Pack
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {productsOrders !== null && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
          }}>
          <Typography
            sx={{
              background: '#F5F5F5',
              padding: '10px',
              fontWeight: 'bold',
              fontSize: '14px'
            }}>
            {isLoading ? (
              <CircularProgress sx={{ color: '#C8C8CC' }} size={15} />
            ) : (
              productsOrders?.header?.name
            )}
            {!isLoading && (
              <span
                style={{
                  fontWeight: 400,
                  fontSize: '12px'
                }}>{` (${productsOrders?.header?.schedule})`}</span>
            )}
          </Typography>
          <TextField
            placeholder={'Type here to search'}
            size="small"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              debounceOnChange(e.target.value);
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingLeft: '6px',
                fontSize: '12px',
                border: 'none'
              },
              '& fieldset': { border: 'none' },
              bgcolor: '#F5F5F5',
              minHeight: '22px',
              width: '30%',
              borderRadius: '5px'
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <GridSearchIcon sx={{ width: '12px' }} />
                </InputAdornment>
              )
            }}
          />
          <TableContainer>
            <Table size="small" sx={{ border: '1px solid #F5F5F5' }}>
              <TableHead>
                <TableRow sx={{ background: '#F5F5F5' }} key={'menu-row'}>
                  {menus.map((item, index) => (
                    <SortableTableHeader
                      key={index}
                      label={item.label}
                      name={item.name}
                      sort={sort}
                      direction={direction}
                      icon={item?.icon}
                      info={item?.info}
                      summary={item?.summary}
                      sortable={item?.sortable}
                      handleHeaderOnClick={handleHeaderOnClick}
                    />
                  ))}
                  <TableCell sx={{ border: '1px solid #C8C8CC' }} align="left">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              {!isLoading && productsOrders?.orders?.length > 0 ? (
                <TableBody sx={{ border: '1px solid #C8C8CC' }}>
                  {productsOrders?.orders?.map((item, index) => {
                    var chefs = [];
                    return (
                      <TableRow hover tabIndex={-1} sx={{ cursor: 'pointer' }}>
                        {/* name */}
                        <TableCell align="left">
                          <Typography fontSize={13} noWrap>
                            {item?.name}
                            <span style={{ display: 'block', fontSize: 11 }}>
                              {item?.phoneNumber}
                            </span>
                          </Typography>
                        </TableCell>
                        {/* package */}
                        <TableCell
                          align="left"
                          sx={{
                            fontSize: '13px'
                          }}>
                          {item?.package}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontSize: '13px',
                            color: item?.address !== 'Dine In' ? '#171717' : 'red'
                          }}>
                          {item?.address !== 'Dine In' ? item?.address : 'Dine In'}
                        </TableCell>
                        <TableCell align="left">
                          {item?.orders.map((order, index) => {
                            order?.orderStatuses.map((status) => {
                              if (status?.staff !== null) chefs[status?.staffId] = status?.staff;
                            });
                            return (
                              <BootstrapTooltip title={order?.note} arrow followCursor>
                                <Typography
                                  key={index}
                                  sx={{
                                    fontSize: '13px',
                                    color: order?.note !== null ? '#1A72DD' : '#171717',
                                    fontWeight: order?.note !== null ? 600 : 400
                                  }}
                                  noWrap>
                                  {`${order?.quantity} - ${order?.name}`}
                                </Typography>
                              </BootstrapTooltip>
                            );
                          })}
                        </TableCell>
                        <TableCell align="left">
                          {chefs.map((chef) => {
                            return (
                              <Typography sx={{ fontSize: '13px' }} noWrap>
                                {chef}
                              </Typography>
                            );
                          })}
                        </TableCell>
                        <TableCell align="left">
                          <Box textAlign={'center'}>
                            {showAction ? (
                              <Button
                                variant="contained"
                                sx={{
                                  margin: 0,
                                  paddingX: 0,
                                  paddingY: '4px',
                                  fontSize: '11px',
                                  fontWeight: 500,
                                  borderRadius: '8px'
                                }}
                                onClick={async () => {
                                  setSelectedProductToViewRaw(item);
                                  setModalActive(true);
                                }}>
                                <PackIcon />
                                <span style={{ marginLeft: '5px' }}> Pack</span>
                              </Button>
                            ) : (
                              '-'
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow sx={{ border: '1px solid #C8C8CC' }}>
                    <TableCell colSpan={8}>
                      <Typography noWrap sx={{ margin: 'auto', textAlign: 'center' }}>
                        {isLoading ? (
                          <CircularProgress sx={{ color: '#C8C8CC' }} size={25} />
                        ) : (
                          'Empty Data'
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
      )}

      <ModalContainer
        Content={ContentModalOrders}
        modalActive={modalActive}
        onClose={() => setModalActive(false)}
      />
    </>
  );
};

export default OrdersTab;
