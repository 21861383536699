import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { DrawerHeader, HeaderBar } from './shared';
import { addDuration, getCateringSchedules } from '../api/catering';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { renderRequired } from './shared/emptyData';

const CateringSchedule = ({ isNavigationDrawerOpen, onMenuButtonClick, snackbarAction }) => {
  const [cateringPlans, setCateringPlans] = useState([]);
  const [filteredCateringPlans, setFilteredCateringPlans] = useState([]);

  const [addDurationActive, setAddDurationActive] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues
  } = useForm({
    defaultValues: {
      name: '',
      start: '',
      end: ''
    }
  });

  const onSubmit = async ({ name, start, end }) => {
    const payload = {
      name,
      start_deliver_at: start,
      finish_deliver_at: end
    };
    try {
      await addDuration(payload);
      snackbarAction.returnSuccess();
      setAddDurationActive(false);
      await fetchCateringSchedules();
    } catch (e) {
      snackbarAction.returnError();
    }
  };

  const MenuCard = ({ menu }) => {
    const { id, name, startDeliverAt, finishDeliverAt } = menu;
    return (
      <ButtonBase
        sx={{
          boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)',
          background: '#FFFFFF',
          // marginBottom: '1px',
          width: '100%'
        }}
        to={`/catering-schedule/${id}`}
        state={menu}
        component={RouterLink}>
        <CardContent>
          <Typography variant="h6" sx={{ fontWeight: 700, textAlign: 'center' }}>
            {name}
          </Typography>
          <Typography variant="p" sx={{ fontWeight: 700, textAlign: 'center' }}>
            ({startDeliverAt.substring(0, 5)} - {finishDeliverAt.substring(0, 5)})
          </Typography>
        </CardContent>
      </ButtonBase>
    );
  };

  const CateringPlanList = () => (
    <Grid container spacing={{ xs: 1, md: 1 }} sx={{ overflow: 'auto' }}>
      {filteredCateringPlans.map((item, index) => (
        <Grid item xs={3} sm={3} md={3} key={index}>
          <MenuCard menu={item} />
        </Grid>
      ))}
    </Grid>
  );

  const fetchCateringSchedules = async () => {
    try {
      const { data } = await getCateringSchedules();
      setCateringPlans(data);
      setFilteredCateringPlans(data);
    } catch (error) {
      snackbarAction.returnError();
    }
  };

  useEffect(() => {
    fetchCateringSchedules();
  }, []);

  const handleSearchFieldChange = (event) => {
    setFilteredCateringPlans(
      cateringPlans.filter((plan) =>
        new RegExp(`${event.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}`, 'i').test(plan.name)
      )
    );
  };

  const validateEnd = (time) => {
    const start = getValues('start');
    // console.log((time > start))
    return time > start;
  };

  const validateStart = (time) => {
    const end = getValues('end');
    return time < end;
  };

  return (
    <>
      <HeaderBar
        isNavigationDrawerOpen={isNavigationDrawerOpen}
        onMenuButtonClick={onMenuButtonClick}
        title="Catering Schedule"
      />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          flexGrow: 1,
          margin: '0 37px'
        }}>
        <DrawerHeader />
        {!addDurationActive && (
          <>
            <div
              style={{
                display: 'flex',
                marginTop: '2rem',
                marginBottom: '2rem'
              }}>
              <TextField
                size="small"
                placeholder="Search"
                sx={{ width: '40%' }}
                onChange={(event) => handleSearchFieldChange(event.target.value)}
              />
              <Button
                variant="contained"
                size="medium"
                sx={{ ml: 2, width: '20%' }}
                onClick={() => setAddDurationActive(true)}>
                Add Schedule
              </Button>
            </div>
            <CateringPlanList />
          </>
        )}

        {addDurationActive && (
          <>
            <div
              className="display-flex"
              style={{ cursor: 'pointer' }}
              onClick={() => setAddDurationActive(false)}>
              <ArrowBackIos />
              <span style={{ fontSize: '16px' }}>Add Schedule</span>
            </div>

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Card sx={{ mt: 2, border: 'none', boxShadow: 'none' }}>
                  <CardContent>
                    <Typography variant="h5" sx={{ color: '#252733', fontWeight: 700 }}>
                      Catering Schedule Settings
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card sx={{ border: 'none', boxShadow: 'none' }}>
                  <CardContent>
                    <div className="display-flex-space-between cursor-pointer">
                      <div>
                        <p style={{ fontSize: '16px', margin: 0 }}>Info</p>
                        <p style={{ fontSize: '12px', margin: 0 }}>Input Title and Time</p>
                      </div>
                      <ArrowForwardIos
                        sx={{
                          fontSize: '25px',
                          marginTop: '5px',
                          cursor: 'pointer'
                        }}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={8}>
                <Card
                  sx={{
                    border: 'none',
                    boxShadow: 'none'
                  }}>
                  <CardContent>
                    <Controller
                      name={'name'}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div style={{ marginBottom: '2vh' }}>
                          <p className="form-label">Catering Schedule Title</p>
                          <TextField
                            error={errors?.name}
                            onChange={onChange}
                            value={value}
                            placeholder="Example: Paket 3 hari bersama"
                            sx={{ width: '100%' }}
                            size="small"
                          />
                          {errors?.name && renderRequired()}
                        </div>
                      )}
                    />

                    <div className="display-flex" style={{ marginBottom: '2vh' }}>
                      <Controller
                        name={'start'}
                        control={control}
                        rules={{ required: true, validate: validateStart }}
                        render={({ field: { onChange, value } }) => (
                          <div style={{ marginRight: '2vw' }}>
                            <p className="form-label">Time From</p>
                            <TextField
                              error={errors?.start}
                              onChange={onChange}
                              value={value}
                              sx={{ width: '100%' }}
                              size="small"
                              type="time"
                              step="1"
                              inputProps={{
                                step: 1
                              }}
                            />
                            {errors?.start && renderRequired()}
                          </div>
                        )}
                      />
                      <Controller
                        name={'end'}
                        control={control}
                        rules={{ required: true, validate: validateEnd }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            <p className="form-label">Time Until</p>
                            <TextField
                              error={errors?.end}
                              onChange={onChange}
                              value={value}
                              sx={{ width: '100%' }}
                              size="small"
                              type="time"
                              step="1"
                              inputProps={{
                                step: 1
                              }}
                            />
                            {errors?.end && renderRequired()}
                          </div>
                        )}
                      />
                    </div>
                    <Button
                      variant="contained"
                      size="medium"
                      sx={{ width: '15%' }}
                      onClick={handleSubmit(onSubmit)}>
                      Save
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};

export default CateringSchedule;
