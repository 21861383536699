import { useEffect, useState } from 'react';
import { Card, CardContent, Container, Typography } from '@mui/material';

import { getScheduledList } from '../../../api/catering';
import dayjs from 'dayjs';

const CalendarStack = ({ selectedDate, moveAhead, moveBehind, value, onClick, calendarList }) => {
  const [listOfSchedules, setListOfSchedules] = useState([]);

  const scheduleList = async () => {
    const payload = {
      start_date: dayjs(selectedDate).subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs(selectedDate).add(1, 'month').format('YYYY-MM-DD')
    };

    try {
      const { data } = await getScheduledList(payload.start_date, payload.end_date);
      setListOfSchedules(data);
    } catch (error) {
      console.error(e);
    }
  };

  useEffect(async () => {
    await scheduleList();
  }, []);

  const CalendarList = () => (
    <div style={{ display: 'flex', cursor: 'pointer' }}>
      {calendarList.map((data, index) => {
        const findOrders = listOfSchedules.find((obj) => obj.date === data?.date);
        return <CalendarCard key={index} data={data} numberOfOrders={findOrders?.numberOfOrders} />;
      })}
    </div>
  );

  const CalendarCard = ({ data, numberOfOrders }) => {
    const { date, day, dayName, monthName } = data;
    return (
      <Card
        sx={{
          width: 80,
          height: 100,
          marginRight: '5px',
          backgroundColor: `${date === selectedDate ? '#363740' : '#ffffff'}`,
          transform: `translateX(${value}%)`
        }}
        className="glide"
        onClick={() => onClick(date)}>
        {numberOfOrders > 0 && (
          <div
            style={{
              height: 21,
              width: 21,
              backgroundColor: 'red',
              position: 'absolute',
              marginTop: 5,
              marginLeft: 75,
              borderRadius: '50%',
              textAlign: 'center',
              alignItems: 'center'
            }}>
            <div
              style={{
                textAlign: 'center',
                color: 'white',
                verticalAlign: 'middle',
                fontSize: '12.5px',
                fontWeight: 'bold',
                margin: 'auto',
                justifyContent: 'center',
                paddingTop: '2px'
              }}>
              {numberOfOrders > 99 ? '99+' : numberOfOrders}
            </div>
          </div>
        )}

        <CardContent sx={{ textAlign: 'center' }}>
          <Typography
            sx={{
              fontSize: 10,
              color: `${date === selectedDate ? '#ffffff' : '#000000'}`
            }}>
            {dayName}
          </Typography>
          <Typography
            variant="h4"
            component="div"
            sx={{
              fontWeight: 'bolder',
              color: `${date === selectedDate ? '#ffffff' : '#000000'}`
            }}>
            {day}
          </Typography>
          <Typography
            sx={{
              fontWeight: 'normal',
              fontSize: 15,
              color: `${date === selectedDate ? '#ffffff' : '#000000'}`
            }}>
            {monthName}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <Container>
      <div className="display-flex-space-between">
        <h5 className="moveBehind pointer" onClick={moveBehind}>
          {'<'}
        </h5>
        <div className="glider">
          <CalendarList />
        </div>
        <h5 className="moveAhead pointer" onClick={moveAhead}>
          {'>'}
        </h5>
      </div>
    </Container>
  );
};

export default CalendarStack;
