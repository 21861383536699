import { useLocation, useParams } from 'react-router-dom';
import { MoreHoriz } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { Box, Button, Grid, Switch, TextField, Typography } from '@mui/material';

import { DrawerHeader, HeaderBar } from './shared';
import '../assets/style.css';
import {
  changeCateringPlanStatus,
  getCateringSchedules,
  getCateringSchedulesPlans
} from '../api/catering';
import { formatNumberToCurrency } from '../utils/currency';
import AddPlan from './shared/Catering/AddPlan';
import EditPlan from './shared/Catering/EditPlan';
import { returnEmptyData } from './shared/emptyData';

const CateringScheduleDetail = ({ isNavigationDrawerOpen, onMenuButtonClick, snackbarAction }) => {
  const [isEditActive, setIsEditActive] = useState(false);
  const [isAddActive, setIsAddActive] = useState(false);
  const [plans, setPlans] = useState([]);
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [typeFetch, setTypeFetch] = useState([]);

  const getTypeMenu = async () => {
    try {
      const { data } = await getCateringSchedules();
      setTypeFetch(data);
    } catch (error) {
      snackbarAction.returnError();
    }
  };

  const { menuId } = useParams();
  const { state } = useLocation();

  const getSchedulesPlans = async () => {
    try {
      const { data } = await getCateringSchedulesPlans(menuId);
      setPlans(data);
      setFilteredPlans(data);
    } catch (e) {
      snackbarAction.returnError();
    }
  };

  useEffect(() => {
    getSchedulesPlans();
    getTypeMenu();
  }, []);

  const CateringPlanList = () => (
    <Grid container spacing={{ xs: 2, md: 3 }} sx={{ overflow: 'auto' }}>
      {plans?.length > 0 ?
        filteredPlans?.map((data, index) => <CateringPlanCard key={index} data={data} />) : returnEmptyData()}
    </Grid>
  );

  const CateringPlanCard = ({ data }) => {
    const { id, finalPrice, name, status } = data;
    const [checked, setChecked] = useState(status === 1);

    const handleChangeStatus = async (event) => {
      setChecked(event?.target?.checked);
      await changeCateringPlanStatus(id, event?.target?.checked ? '1' : '0');
    };
    return (
      <Grid item xs={4} sm={4} md={4}>
        <div className="product-card">
          <div className="display-flex-space-between">
            <Typography variant="h6" sx={{ fontSize: '24px', fontWeight: '600' }}>
              {name}
            </Typography>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setIsEditActive(true);
                setSelectedPlan(data);
              }}>
              <MoreHoriz />
            </div>
          </div>

          <div className="display-flex-space-between">
            <Typography variant="p" sx={{ paddingTop: '8px' }}>
              {formatNumberToCurrency(finalPrice)}
            </Typography>
            <Switch checked={checked} onChange={handleChangeStatus} />
          </div>
        </div>
      </Grid>
    );
  };

  return (
    <>
      <HeaderBar
        isNavigationDrawerOpen={isNavigationDrawerOpen}
        onMenuButtonClick={onMenuButtonClick}
        title={`${state?.name} Menu`}
      />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          margin: '0 37px 37px 37px'
        }}>
        <DrawerHeader />

        {!isEditActive && !isAddActive && (
          <>
            <div
              className="display-flex"
              style={{
                marginTop: '2rem',
                marginBottom: '2rem'
              }}>
              <TextField size="small" placeholder="Search" sx={{ width: '40%' }} />
              <Button
                variant="contained"
                size="medium"
                sx={{ ml: 2, width: '20%' }}
                onClick={() => setIsAddActive(true)}>
                Add Plan
              </Button>
            </div>

            <CateringPlanList />
          </>
        )}

        {isEditActive && (
          <EditPlan
            dataType={typeFetch}
            onBack={async () => {
              setIsEditActive(false);
              await getSchedulesPlans();
            }}
            selectedPlan={selectedPlan}
            menuId={Number(menuId)}
            snackbarAction={snackbarAction}
            getSchedulesPlans={async() => {
              await getSchedulesPlans();
            }}
            plans={plans}
          />
        )}

        {isAddActive && (
          <AddPlan
            dataType={typeFetch}
            onBack={async () => {
              setIsAddActive(false);
              await getSchedulesPlans();
            }}
            menuId={Number(menuId)}
            snackbarAction={snackbarAction}
          />
        )}
      </Box>
    </>
  );
};

export default CateringScheduleDetail;
