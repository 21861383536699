import { kitchenAxios } from '../utils/api';

export const setStatusServing = (orderStatusId) =>
  kitchenAxios.post(`order-statuses/${orderStatusId}/serving`);

export const setStatusServed = (orderStatusId) =>
  kitchenAxios.post(`order-statuses/${orderStatusId}/served`);

export const getOverview = (config) => kitchenAxios.get('overview', config);
export const getOverviewBasic = () => kitchenAxios.get('overview');

export const cancel = (invoiceId) => kitchenAxios.post(`invoices/${invoiceId}/cancel`);

export const cancelRequest = (data) => kitchenAxios.post('invoice/cancel-requests', data);

export const getAvailableCooks = (stationId) =>
  kitchenAxios.get('cooks', { params: { stationId } });

export const setStatusCooked = (orderStatusIds) => kitchenAxios.post('cooked', { orderStatusIds });

export const ongoingRecook = (orderStatusIds) =>
  kitchenAxios.post('ongoing/recook', {
    orderStatusIds
  });

export const onFinishRecook = ({ orderStatusIds, staffId, reason, productId }) =>
  kitchenAxios.post('onfinish/recook', {
    orderStatusIds,
    staffId,
    reason,
    productId
  });

export const assignCooks = (cookAssignments) =>
  kitchenAxios.post('cooks/assign', { cookAssignments });

export const paid = (invoiceId) => kitchenAxios.put(`invoices/${invoiceId}/paid`);

export const remind = (invoiceId) => kitchenAxios.post(`invoices/${invoiceId}/remind`);
