import React from 'react';

import { Grid } from '@mui/material';

export const returnEmptyData = () => {
  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justifyContent='center'
      style={{ minHeight: '50vh' }}
    >

      <Grid item xs={3}>
        <p style={{ textAlign: 'center' }}><b>Empty Data</b></p>
      </Grid>

    </Grid>
  );
};

export const renderRequired = (message = "required") => (
  <small style={{ color: 'red', fontStyle: 'italic' }}>{message}</small>
);
