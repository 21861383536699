import { createContext, useState, useEffect } from 'react';
import { Socket, io } from 'socket.io-client';

export const SocketIOContext = createContext({});

export const SocketIOContextProvider = ({ children }) => {
  // Connect to the Socket.IO server
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const socketConnect = io(
      'https://socket.mri.id?user_id=kanpai&license_id=41Q8ly-Mb6PQj-5EmBSC-A8q06H',
      {
        transports: ['websocket'],
        reconnection: true,
        reconnectionAttempts: 5
      }
    );

    setSocket(socketConnect);

    return () => {
      socket.close();
    };
  }, []);

  const [triggeredFetch, setTriggeredFetch] = useState(0);
  const [newOrdersCount, setNewOrdersCount] = useState(0);

  return (
    <SocketIOContext.Provider
      value={{ socket, triggeredFetch, setTriggeredFetch, newOrdersCount, setNewOrdersCount }}>
      {children}
    </SocketIOContext.Provider>
  );
};
