import { Menu as MenuIcon } from '@mui/icons-material';
import {
  AppBar as MuiAppBar,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Toolbar,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { DRAWER_WIDTH } from '../../constants/navigationDrawerConstants';
import { getCurrentTimeString, SubstringSecond } from '../../utils/time';
import { getCateringSchedules } from '../../api/catering';
import { capitalizeFirstLetter } from '../../utils';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const HeaderBar = ({
  isNavigationDrawerOpen,
  title,
  onMenuButtonClick,
  isProductType = false,
  changeType,
  selectedDate
}) => {
  const [time, setTime] = useState(getCurrentTimeString());
  const [productsType, setProductsType] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState(0);

  const getProductsType = async () => {
    try {
      const { data } = await getCateringSchedules(selectedDate);
      setProductsType(data);
      setSelectedProductType(data?.[0]?.id);
      changeType(data?.[0]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isProductType) {
      getProductsType();
    }

    const timeIntervalId = setInterval(() => setTime(getCurrentTimeString()), 1000);

    return () => clearInterval(timeIntervalId);
  }, [selectedDate]);

  return (
    <AppBar position="fixed" open={isNavigationDrawerOpen} elevation={0}>
      <Toolbar>
        <IconButton
          color="inherit"
          onClick={onMenuButtonClick}
          edge="start"
          sx={{
            marginRight: '36px',
            ...(isNavigationDrawerOpen && { display: 'none' })
          }}>
          <MenuIcon />
        </IconButton>
        <div style={{ display: 'flex', flexGrow: '1', alignItems: 'center' }}>
          <Typography variant="h5" noWrap component="div" sx={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
          {isProductType && productsType?.length > 0 && (
            <FormControl size="small" sx={{ ml: 2 }}>
              <Select
                value={selectedProductType}
                onChange={(e) => {
                  setSelectedProductType(e.target.value);
                  changeType(productsType.find((item) => item.id === e.target.value));
                }}
                sx={{ padding: 0 }}>
                {productsType?.length > 0 &&
                  productsType?.map((data, index) => (
                    <MenuItem
                      key={index}
                      value={data?.id}
                      sx={{ display: 'flex', flexDirection: 'row' }}>
                      {data?.order > 0 && (
                        <span
                          style={{
                            color: 'red',
                            marginRight: 3
                          }}>
                          {`(${data?.order})`}
                        </span>
                      )}
                      {capitalizeFirstLetter(data?.name)} ({SubstringSecond(data?.startDeliverAt)}-
                      {SubstringSecond(data?.finishDeliverAt)})
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </div>
        <Stack direction="row" sx={{ alignItems: 'baseline' }} spacing={1}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
            TIME:
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {time}
          </Typography>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

HeaderBar.propTypes = {
  isNavigationDrawerOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onMenuButtonClick: PropTypes.func.isRequired,
  isProductType: PropTypes.bool,
  productsType: PropTypes.array
};

export default HeaderBar;
