import { Alert, Snackbar as SnackbarDefault } from '@mui/material';

const Snackbar = ({
  open = true,
  message = 'Snackbar Message',
  type = 'success',
  handleCloseSnackbar
}) => {
  const vertical = 'bottom';
  const horizontal = 'right';

  return (
    <SnackbarDefault
      open={open}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical, horizontal }}
      key={vertical + horizontal}>
      <Alert severity={type}>{message}</Alert>
    </SnackbarDefault>
  );
};

export default Snackbar;
