import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { getFinishedTrays } from '../api/finishTray';
import { setStatusServed, setStatusServing } from '../api/orders';
import { DrawerHeader, HeaderBar } from './shared';

const OrderCard = ({ orderStatus, setClickStation, setClickStationTake, setIdOrders }) => {
  const handleTakeClick = async () => {
    // try {
    //   await setStatusServing(orderStatus.id);
    //   location.reload();
    // } catch (error) {
    //   console.error(error);
    // }
    setIdOrders(orderStatus.id);
    setClickStationTake(true);
  };

  const handleFinishClick = async () => {
    // try {
    //   await setStatusServed(orderStatus.id);
    //   // location.reload();
    // } catch (error) {
    //   console.error(error);
    // }
    // setTrigerClick(true);
    setIdOrders(orderStatus.id);
    setClickStation(true);
  };

  return (
    <Paper
      sx={{
        p: 2,
        height: 1,
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Typography sx={{ fontSize: '1.875rem' }}>
        {orderStatus.table ? orderStatus.table.name : 'Takeaway'}
      </Typography>
      <Box sx={{ flex: 1 }}>
        <Typography color="grey.600" sx={{ mt: 2.5 }}>
          {orderStatus.invoiceOrder.product.name}
        </Typography>
        {orderStatus.invoiceOrder.note !== null ? (
          <Typography variant="body2" color="grey.500">
            {orderStatus.invoiceOrder.note}
          </Typography>
        ) : (
          <Typography variant="body2" color="grey.500">
            -
          </Typography>
        )}
      </Box>
      {orderStatus.status === 200 ? (
        <Button
          variant="contained"
          fullWidth
          sx={{
            mt: 2.5
          }}
          color="success"
          size="large"
          onClick={handleFinishClick}>
          Finish
        </Button>
      ) : (
        <Button
          variant="contained"
          fullWidth
          sx={{
            mt: 2.5
          }}
          color="warning"
          size="large"
          onClick={handleTakeClick}>
          Take
        </Button>
      )}
    </Paper>
  );
};

const StationSection = ({ station, setClickStation, setClickStationTake, setIdOrders }) => (
  <Box sx={{ mt: 4.5 }}>
    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
      {station.stationName}
    </Typography>
    <Grid container sx={{ mt: -2 }} spacing={4.5}>
      {station.invoiceOrderStatuses.map((orderStatus) => (
        <Grid item xs={3} lg={2.4} key={orderStatus.id}>
          <OrderCard
            orderStatus={orderStatus}
            setClickStation={setClickStation}
            setIdOrders={setIdOrders}
            setClickStationTake={setClickStationTake}
          />
        </Grid>
      ))}
    </Grid>
  </Box>
);

const FinishTray = ({ isNavigationDrawerOpen, onMenuButtonClick }) => {
  const [stations, setStations] = useState([]);
  const [clickStation, setClickStation] = useState(false);
  const [clickStationTake, setClickStationTake] = useState(false);
  const [idOrders, setIdOrders] = useState(0);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const { data } = await getFinishedTrays();
        setStations(data);
      } catch (error) {
        console.error(error);
      }
    };

    bootstrapAsync();
  }, []);

  useEffect(() => {
    const fetchUlang = async () => {
      try {
        const { data } = await getFinishedTrays();
        setStations(data);
      } catch (error) {
        console.error(error);
      } finally {
        setClickStation(false);
        setClickStationTake(false);
      }
    };
    const updateFinish = async () => {
      try {
        await setStatusServed(idOrders);
      } catch (error) {
        console.error(error);
      } finally {
        // setStations([]);
        setIdOrders(0);
        fetchUlang();
        setClickStation(false);
      }
    };
    const updateTake = async () => {
      try {
        await setStatusServing(idOrders);
        // location.reload();
      } catch (error) {
        console.error(error);
      } finally {
        // setStations([]);
        setIdOrders(0);
        fetchUlang();
        setClickStationTake(false);
      }
    };
    if (clickStation) {
      return updateFinish();
      // console.log('cekkkss');
    }
    if (clickStationTake) {
      return updateTake();
      // console.log('helopoo');
    }
  }, [clickStation, idOrders, clickStationTake]);

  return (
    <>
      <HeaderBar
        isNavigationDrawerOpen={isNavigationDrawerOpen}
        onMenuButtonClick={onMenuButtonClick}
        title="Finish Tray"
      />
      <Box component="main" sx={{ flexGrow: 1, p: 4.5, pt: 0.625 }}>
        <DrawerHeader />
        {stations.map((station) => (
          <StationSection
            station={station}
            key={station.id}
            setClickStation={setClickStation}
            setClickStationTake={setClickStationTake}
            setIdOrders={setIdOrders}
          />
        ))}
      </Box>
    </>
  );
};

// const ORDER_STATUS_TYPE = PropTypes.shape({
//   id: PropTypes.number.isRequired,
//   status: PropTypes.number.isRequired,
//   table: PropTypes.shape({
//     id: PropTypes.number.isRequired,
//     name: PropTypes.string.isRequired,
//   }).isRequired,
//   invoiceOrder: PropTypes.shape({
//     id: PropTypes.number.isRequired,
//     note: PropTypes.string,
//     product: PropTypes.shape({
//       id: PropTypes.number.isRequired,
//       name: PropTypes.string.isRequired,
//     }).isRequired,
//   }).isRequired,
// }).isRequired;

// OrderCard.propTypes = {
//   orderStatus: ORDER_STATUS_TYPE,
// };

// StationSection.propTypes = {
//   station: PropTypes.shape({
//     id: PropTypes.number.isRequired,
//     stationName: PropTypes.string.isRequired,
//     invoiceOrderStatuses: PropTypes.arrayOf(ORDER_STATUS_TYPE).isRequired,
//   }).isRequired,
// };

// FinishTray.propTypes = {
//   isNavigationDrawerOpen: PropTypes.bool.isRequired,
//   onMenuButtonClick: PropTypes.func.isRequired,
// };

export default FinishTray;
