import { kitchenAxios } from '../utils/api';

export const getReservation = () => kitchenAxios.get('reservations');

export const putReserved = ({ idTable, idReservation }) =>
  kitchenAxios.put(`reservations/${idReservation}/set-reserved`, { tableId: idTable });

export const makeReserved = (idReservation) =>
  kitchenAxios.put(`reservations/${idReservation}/set-reserved`);

export const cancelReserved = (id) => kitchenAxios.put(`reservations/${id}/cancel`);

export const finishReserved = (id) => kitchenAxios.put(`reservations/${id}/set-free`);

export const makeVerify = (id) => kitchenAxios.put(`reservations/${id}/set-verify`);

export const aprroveReservationOrder = (id) => kitchenAxios.put(`reservations/${id}/set-approve`);
export const declineReservationOrder = (id) =>
  kitchenAxios.put(`reservations/${id}/cancel-reserve`);
