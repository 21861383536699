import { createContext, useState, useCallback, useEffect } from 'react';
import { getAuthDetail, logIn as requestLogIn, logOut as requestLogOut } from '../api/auth';

export const AuthContext = createContext({
  accessToken: null,
  admin: null,
  logIn: async () => {},
  logOut: async () => {},
  checkAccessToken: async () => {}
});

export const AuthContextProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
  const [admin, setAdmin] = useState();

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
    } else {
      localStorage.removeItem('accessToken');
    }
  }, [accessToken]);

  const logIn = async ({ emailAddress, password }) => {
    const { data } = await requestLogIn({ emailAddress, password });
    setAdmin(data.administrator);
    setAccessToken(data.accessToken);
    localStorage.setItem('role', data.administrator.roles[0]);
  };

  const logOut = async () => {
    try {
      if (window.navigator.onLine) {
        await requestLogOut();
      }
    } finally {
      setAccessToken(null);
      setAdmin(null);
    }
  };

  const checkAccessToken = useCallback(async () => {
    try {
      const { data } = await getAuthDetail();
      setAdmin(data);
    } catch (error) {
      setAccessToken(null);
      throw error;
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        admin,
        accessToken,
        logIn,
        logOut,
        checkAccessToken
      }}>
      {children}
    </AuthContext.Provider>
  );
};
