export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const stringHandler = (string) => {
  if (string === '' || string === undefined || string === null) {
    return '-';
  }
  return string;
};

export const priceHandler = (value) => {
  const tax = 0.10 * value;
  const service = 0.055 * value;
  const grandTotal = value + tax + service;

  return { tax, service, grandTotal };
};
