import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled
} from '@mui/material';

import {
  getCateringDrivers,
  getCateringsSchedulesPacked,
  sendPacked
} from '../../../../api/catering';
import { GridSearchIcon } from '@mui/x-data-grid';
import {
  AddressIcon,
  CheckBlueIcon,
  CheckPaleIcon,
  CookedIcon,
  InProgressIcon,
  PackageIcon,
  SendIcon
} from '../../../../assets';
import InfoIcon from '@mui/icons-material/Info';
import SortableTableHeader from './SortableTableHeader';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import debounce from 'lodash.debounce';
import dayjs from 'dayjs';

const PackedTab = ({ date, selectedType, snackbarAction, isLoading, setIsLoading }) => {
  const [productsPacked, setProductsPacked] = useState(null);
  const [selectedPacked, setSelectedPacked] = useState([]);
  const [search, setSearch] = useState('');
  const [packedKeyword, setPackedKeyword] = useState('');
  const [sort, setSort] = useState('name');
  const [direction, setDirection] = useState('asc');
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [isBtnDisable, setIsButtonDisable] = useState(false);

  const nowDate = dayjs().format('YYYY-MM-DD');
  const showAction = date === nowDate;

  const handleHeaderOnClick = (name, direction) => {
    setSort(name);
    setDirection(direction);
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black
    }
  }));

  const menus = [
    { label: 'Name', name: 'name', sortable: true },
    { label: 'Package', name: 'package', icon: <PackageIcon />, sortable: true },
    { label: 'Address', name: 'address', icon: <AddressIcon />, sortable: true },
    {
      label: 'Orders',
      info: (
        <BootstrapTooltip title="If the text is bold and colored, the details of the note can be seen.">
          <InfoIcon sx={{ fontSize: 12 }} />
        </BootstrapTooltip>
      ),
      name: 'orders',
      icon: <CookedIcon />
    },
    {
      label: 'Chef',
      name: 'chef',
      icon: <InProgressIcon />
    }
  ];

  const debounceOnChange = useRef(
    debounce((value) => {
      setPackedKeyword(value);
    }, 300)
  ).current;

  const getProductsPacked = async (newDate = date) => {
    try {
      const { data } = await getCateringsSchedulesPacked(
        selectedType?.id,
        newDate,
        sort,
        direction,
        packedKeyword
      );
      setProductsPacked(data);
    } catch (error) {
      snackbarAction.returnError();
    }
  };

  const getDrivers = async () => {
    try {
      const { data } = await getCateringDrivers();
      setDrivers(data);
    } catch (error) {
      snackbarAction.returnError();
    }
  };

  const handleMultiplePackedSend = async () => {
    try {
      setIsButtonDisable(true);
      const tempIds = [];

      selectedPacked.map((status, id) => {
        if (status === true && selectedDriver !== null) tempIds.push(id);
      });

      if (tempIds.length < 1) return snackbarAction.returnError('Order or driver is not selected');

      await sendPacked(tempIds, selectedDriver);
      setSelectedDriver(null);
      setSelectedPacked([]);
      await getProductsPacked();
      snackbarAction.returnSuccess();
    } catch (e) {
      snackbarAction.returnError();
    }
    setIsButtonDisable(false);
  };

  const handleChange = (event) => {
    setSelectedDriver(event.target.value);
  };

  useEffect(async () => {
    setIsLoading(true);
    await getProductsPacked(date);
    setIsLoading(false);
  }, [date, selectedType, sort, direction, packedKeyword]);

  useEffect(async () => {
    await getDrivers();
  }, []);

  const handleCheck = (event, id) => {
    var oldValue = selectedPacked;
    oldValue[id] = event.target.checked;
    setSelectedPacked(oldValue);
  };

  return (
    <>
      {productsPacked !== null && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Typography
            sx={{
              background: '#F5F5F5',
              padding: '10px',
              fontWeight: 'bold',
              fontSize: '14px'
            }}>
            {isLoading ? (
              <CircularProgress sx={{ color: '#C8C8CC' }} size={15} />
            ) : (
              productsPacked?.header?.name
            )}
            {!isLoading && (
              <span
                style={{
                  fontWeight: 400,
                  fontSize: '12px'
                }}>{` (${productsPacked?.header?.schedule})`}</span>
            )}
          </Typography>
          <Box display={'flex'}>
            <TextField
              placeholder={'Type here to search'}
              size="small"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                debounceOnChange(e.target.value);
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  paddingLeft: '6px',
                  fontSize: '12px',
                  border: 'none'
                },
                '& fieldset': { border: 'none' },
                bgcolor: '#F5F5F5',
                minHeight: '22px',
                width: '30%',
                borderRadius: '5px'
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <GridSearchIcon sx={{ width: '12px' }} />
                  </InputAdornment>
                )
              }}
            />
            {showAction && productsPacked?.packed?.length > 0 && (
              <Box display={'flex'} gap={1} alignItems={'center'} ml={'auto'}>
                <FormControl variant="standard">
                  <Select
                    sx={{ fontSize: '15px', background: '#F5F5F5', width: '200px', paddingLeft: 1 }}
                    id="outlined-size-small margin-none"
                    displayEmpty
                    defaultValue={''}
                    onChange={(event) => handleChange(event)}>
                    <MenuItem value="">
                      <em>Choose one</em>
                    </MenuItem>
                    {drivers.length > 0 &&
                      drivers.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    {drivers.length === 0 && (
                      <MenuItem value="none" disabled>
                        -
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleMultiplePackedSend();
                  }}
                  disabled={isBtnDisable}
                  size="medium">
                  <SendIcon />{' '}
                  <span style={{ marginLeft: '4px', fontSize: '14px', fontWeight: 300 }}>
                    {' '}
                    Send
                  </span>
                </Button>
              </Box>
            )}
          </Box>
          <TableContainer>
            <Table size="small" sx={{ border: '1px solid #F5F5F5' }}>
              <TableHead>
                <TableRow sx={{ background: '#F5F5F5' }} key={'menu-row'}>
                  {menus.map((item, index) => (
                    <SortableTableHeader
                      key={index}
                      label={item.label}
                      name={item.name}
                      sort={sort}
                      direction={direction}
                      icon={item?.icon}
                      info={item?.info}
                      summary={item?.summary}
                      sortable={item?.sortable}
                      handleHeaderOnClick={handleHeaderOnClick}
                    />
                  ))}
                </TableRow>
              </TableHead>
              {!isLoading && productsPacked?.packed?.length > 0 ? (
                <TableBody sx={{ border: '1px solid #C8C8CC' }}>
                  {productsPacked?.packed?.map((item, index) => {
                    var chefs = [];
                    return (
                      <TableRow hover tabIndex={-1} key={item.id} sx={{ cursor: 'pointer' }}>
                        {/* name */}
                        <TableCell align="left" sx={{ fontSize: '13px' }}>
                          <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                            <Checkbox
                              id={`check-${item?.id}`}
                              icon={<CheckPaleIcon />}
                              onClick={(event) => handleCheck(event, item?.id)}
                              checkedIcon={<CheckBlueIcon />}
                            />{' '}
                            <Typography fontSize={13} noWrap for={`check-${item?.id}`}>
                              {item?.name}
                              <span
                                style={{
                                  display: 'block',
                                  fontSize: 11
                                }}>
                                {item?.phoneNumber}
                              </span>
                            </Typography>
                          </Box>
                        </TableCell>
                        {/* package */}
                        <TableCell
                          align="left"
                          sx={{
                            fontSize: '13px'
                          }}>
                          {item?.package}
                        </TableCell>
                        {/* address */}
                        <TableCell
                          align="left"
                          sx={{
                            fontSize: '13px',
                            color: item?.address !== 'Dine In' ? '#171717' : 'red'
                          }}>
                          {item?.address !== 'Dine In' ? item?.address : 'Dine In'}
                        </TableCell>

                        <TableCell align="left">
                          {item?.orders.map((order, index) => {
                            order?.orderStatuses.map((status) => {
                              if (status?.staff !== null) chefs[status?.staffId] = status?.staff;
                            });
                            return (
                              <BootstrapTooltip title={order?.note} arrow followCursor>
                                <Typography
                                  key={index}
                                  sx={{
                                    fontSize: '13px',
                                    color: order?.note !== null ? '#1A72DD' : '#171717',
                                    fontWeight: order?.note !== null ? 600 : 400
                                  }}
                                  noWrap>
                                  {`${order?.quantity} - ${order?.name}`}
                                </Typography>
                              </BootstrapTooltip>
                            );
                          })}
                        </TableCell>
                        <TableCell align="left">
                          {chefs.map((chef) => {
                            return (
                              <Typography sx={{ fontSize: '13px' }} noWrap>
                                {chef}
                              </Typography>
                            );
                          })}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow sx={{ border: '1px solid #C8C8CC' }}>
                    <TableCell colSpan={8}>
                      <Typography noWrap sx={{ margin: 'auto', textAlign: 'center' }}>
                        {isLoading ? (
                          <CircularProgress sx={{ color: '#C8C8CC' }} size={25} />
                        ) : (
                          'Empty Data'
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export default PackedTab;
