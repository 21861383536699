import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  experimental_sx as sx,
  responsiveFontSizes,
  ThemeProvider
} from '@mui/material/styles';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthContextProvider } from './contexts/AuthContext';
import { SocketIOContextProvider } from './contexts/SocketIOContext';

const theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: ['Inter', 'sans-serif'].join(',')
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          rounded: sx({
            borderRadius: 2
          })
        },
        variants: [
          {
            props: {
              clickable: 'true'
            },
            style: sx({
              '&:hover': {
                bgcolor: 'action.hover'
              }
            })
          },
          {
            props: {
              clickable: 'true',
              color: 'error'
            },
            style: sx({
              color: 'common.white',
              bgcolor: 'error.light',
              '&:hover': {
                bgcolor: 'error.main'
              }
            })
          }
        ]
      },
      MuiButton: {
        styleOverrides: {
          root: sx({
            borderRadius: 2.5,
            textTransform: 'none'
          })
        }
      },
      MuiButtonBase: {
        styleOverrides: {
          root: sx({
            borderRadius: 2
          })
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: sx({
            borderRadius: 2.5
          })
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: sx({
            borderRadius: 2.5
          })
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: sx({
            borderRadius: 2.5
          })
        }
      },
      MuiAppBar: {
        styleOverrides: {
          root: sx({
            bgcolor: 'background.default',
            color: '#252733'
          })
        }
      },
      MuiDrawer: {
        defaultProps: {
          PaperProps: {
            sx: {
              bgcolor: 'drawer.dark',
              color: 'drawer.main'
            }
          }
        }
      }
    },
    palette: {
      background: {
        default: '#F7F8FC'
      },
      drawer: {
        dark: '#363740',
        main: '#A4A6B3',
        light: '#DDE2FF',
        highlight: 'rgba(159,162,180,0.08)'
      }
    }
  })
);

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AuthContextProvider>
          <SocketIOContextProvider>
            <CssBaseline />
            <App />
          </SocketIOContextProvider>
        </AuthContextProvider>
      </ThemeProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);
