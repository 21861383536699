import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';

import {
  assignCook,
  getAvailableChef,
  getCateringsSchedulesFoodBatch
} from '../../../../api/catering';
import ModalContainer from '../ModalContainer';
import SortableTableHeader from './SortableTableHeader';
import {
  CookedIcon,
  DeliveredIcon,
  InProgressIcon,
  NoteIcon,
  StationIcon,
  TodoIcon
} from '../../../../assets';
import { GridSearchIcon } from '@mui/x-data-grid';
import debounce from 'lodash.debounce';
import dayjs from 'dayjs';

const FoodBatchTab = ({ date, selectedType, snackbarAction, isLoading, setIsLoading }) => {
  const [productsFoodBatch, setProductsFoodBatch] = useState(null);
  const [modalActive, setModalActive] = useState(false);
  const [selectedProductToView, setSelectedProductToView] = useState(null);
  const [scheduleSelected, setScheduleSelected] = useState(null);
  const [chef, setChef] = useState([]);
  const [sort, setSort] = useState('name');
  const [direction, setDirection] = useState('asc');
  const [isUpdate, setIsUpdate] = useState(false);
  const [search, setSearch] = useState('');
  const [foodBatchKeyword, setFoodBatchKeyword] = useState('');
  const [isBtnDisable, setIsButtonDisable] = useState(false);

  const nowDate = dayjs().format('YYYY-MM-DD');
  const showAction = date === nowDate;

  const debounceOnChange = useRef(
    debounce((value) => {
      setFoodBatchKeyword(value);
    }, 300)
  ).current;

  const menus = [
    { label: 'Menu', name: 'name' },
    { label: 'Station', name: 'station', icon: <StationIcon /> },
    { label: 'Todo', name: 'todo', icon: <TodoIcon />, summary: productsFoodBatch?.summary?.todo },
    {
      label: 'In Progress',
      name: 'in_progress',
      icon: <InProgressIcon />,
      summary: productsFoodBatch?.summary?.inProgress
    },
    {
      label: 'Cooked',
      name: 'cooked',
      icon: <CookedIcon />,
      summary: productsFoodBatch?.summary?.cooked
    },
    {
      label: 'Delivered',
      name: 'delivered',
      icon: <DeliveredIcon />,
      summary: productsFoodBatch?.summary?.delivered
    },
    { label: 'Total', name: 'total', summary: productsFoodBatch?.summary?.total }
  ];

  const handleHeaderOnClick = (name, direction) => {
    setSort(name);
    setDirection(direction);
  };

  const getProductsFoodBatch = async (newDate = date) => {
    try {
      const { data } = await getCateringsSchedulesFoodBatch(
        selectedType?.id,
        newDate,
        sort,
        direction,
        foodBatchKeyword
      );
      setProductsFoodBatch(data);
    } catch (error) {
      snackbarAction.returnError();
    }
  };

  const getChef = async (data) => {
    const { stationId } = data;

    try {
      const { data } = await getAvailableChef(stationId);
      setChef(data);
    } catch (error) {
      snackbarAction.returnError();
    }
  };

  useEffect(async () => {
    setIsLoading(true);
    await getProductsFoodBatch(date);
    setIsLoading(false);
  }, [date, selectedType, sort, direction, isUpdate, foodBatchKeyword]);

  const handleFoodBatchOrdersApprove = async (todo, qty, selectedChef) => {
    const { id } = selectedProductToView;
    try {
      setIsButtonDisable(true);
      const payload = {
        date: date,
        product_id: id,
        quantity: qty,
        schedule_id: scheduleSelected,
        staff_id: selectedChef
      };
      await assignCook(payload);
      setModalActive(false);
      setIsUpdate((prev) => !prev);
      setIsButtonDisable(false);
      snackbarAction.returnSuccess();
    } catch (error) {
      setIsButtonDisable(false);
      snackbarAction.returnError();
    }
  };

  const ContentFoodBatchModal = () => {
    const [qty, setQty] = useState(0);

    const { name, todo, station } = selectedProductToView;
    const [selectedChef, setSelectedChef] = useState('');

    const handleChange = (event) => {
      setSelectedChef(event.target.value);
    };

    return (
      <>
        <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 700 }}>
          Food Batch Orders
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingX: '30px',
            fontSize: '14px',
            marginTop: '10px',
            gap: '5px'
          }}>
          <Box sx={{ display: 'flex', borderBottom: '1px solid #C8C8CC', paddingY: '8px' }}>
            <Typography fontSize={13}>Menu</Typography>
            <Typography fontSize={13} marginLeft={'auto'}>
              {name}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', borderBottom: '1px solid #C8C8CC', paddingY: '8px' }}>
            <Typography fontSize={13}>Station</Typography>
            <Typography fontSize={13} marginLeft={'auto'}>
              {station}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', borderBottom: '1px solid #C8C8CC', paddingY: '8px' }}>
            <Typography fontSize={13}>Todo</Typography>
            <Typography fontSize={13} marginLeft={'auto'}>
              {todo}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', borderBottom: '1px solid #C8C8CC', paddingY: '5px' }}>
            <Typography fontSize={13} marginRight={'auto'} marginTop={'6px'}>
              Chef
            </Typography>
            <FormControl variant="standard">
              <Select
                sx={{ fontSize: '14px', background: '#F5F5F5', width: '120px' }}
                id="outlined-size-small margin-none"
                value={selectedChef}
                displayEmpty
                onChange={handleChange}>
                <MenuItem value="">
                  <em>Choose one</em>
                </MenuItem>
                {chef.length > 0 &&
                  chef.map((option) => (
                    <MenuItem key={option.staffId} value={option.staffId}>
                      {option.name}
                    </MenuItem>
                  ))}
                {chef.length === 0 && (
                  <MenuItem value="none" disabled>
                    -
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', borderBottom: '1px solid #C8C8CC', paddingY: '8px' }}>
            <Typography fontSize={13} marginRight={'auto'} marginTop={'3px'}>
              Qty
            </Typography>
            <TextField
              sx={{ background: '#F5F5F5' }}
              variant={'standard'}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              size="small"
              placeholder="Insert Quantity"
              type="number"
              InputProps={{
                style: {
                  fontSize: '13px',
                  width: '120px'
                },
                inputProps: {
                  min: 0,
                  max: todo
                }
              }}
              defaultValue={qty}
              onChange={(event) => setQty(event.target.value)}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px',
              marginTop: '10px'
            }}>
            <Button
              variant="contained"
              sx={{ paddingY: '4px', fontSize: '13px' }}
              color="error"
              onClick={() => setModalActive(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ paddingY: '4px', fontSize: '13px' }}
              onClick={() => handleFoodBatchOrdersApprove(todo, qty, selectedChef)}
              disabled={
                selectedChef === null ||
                selectedChef === '' ||
                todo === 0 ||
                qty <= 0 ||
                qty > todo ||
                isBtnDisable
              }>
              Approve
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      {productsFoodBatch !== null && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Typography
            sx={{
              background: '#F5F5F5',
              padding: '10px',
              fontWeight: 'bold',
              fontSize: '14px'
            }}>
            {isLoading ? (
              <CircularProgress sx={{ color: '#C8C8CC' }} size={15} />
            ) : (
              productsFoodBatch?.header?.name
            )}
            {!isLoading && (
              <span
                style={{
                  fontWeight: 400,
                  fontSize: '12px'
                }}>{` (${productsFoodBatch?.header?.schedule})`}</span>
            )}
          </Typography>
          <TextField
            placeholder={'Type here to search'}
            size="small"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              debounceOnChange(e.target.value);
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingLeft: '6px',
                fontSize: '12px',
                border: 'none'
              },
              '& fieldset': { border: 'none' },
              bgcolor: '#F5F5F5',
              minHeight: '22px',
              width: '30%',
              borderRadius: '5px'
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <GridSearchIcon sx={{ width: '12px' }} />
                </InputAdornment>
              )
            }}
          />
          <TableContainer>
            <Table size="small" sx={{ border: '1px solid #F5F5F5' }}>
              <TableHead>
                <TableRow sx={{ background: '#F5F5F5' }} key={'menu-row'}>
                  {menus.map((item, index) => (
                    <SortableTableHeader
                      key={index}
                      label={item.label}
                      name={item.name}
                      sort={sort}
                      direction={direction}
                      icon={item?.icon}
                      summary={item?.summary}
                      handleHeaderOnClick={handleHeaderOnClick}
                      sortable={true}
                    />
                  ))}
                  <TableCell sx={{ border: '1px solid #C8C8CC' }} align="left">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              {!isLoading && productsFoodBatch?.orders?.length > 0 ? (
                <TableBody sx={{ border: '1px solid #C8C8CC' }}>
                  {productsFoodBatch?.orders?.map((item, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={item.id} sx={{ cursor: 'pointer' }}>
                        <TableCell align="left">{item.name}</TableCell>
                        <TableCell align="left">{item.station}</TableCell>
                        <TableCell align="left">{item.todo}</TableCell>
                        <TableCell align="left">{item.inProgress}</TableCell>
                        <TableCell align="left">{item.cooked}</TableCell>
                        <TableCell align="left">{item.delivered}</TableCell>
                        <TableCell align="left">{item.total}</TableCell>
                        <TableCell align="left" style={{ textAlign: 'center' }}>
                          {showAction ? (
                            <Box
                              onClick={async () => {
                                setSelectedProductToView(item);
                                await getChef(item);
                                setModalActive(true);
                                setScheduleSelected(selectedType?.id);
                              }}>
                              <NoteIcon />
                            </Box>
                          ) : (
                            '-'
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow sx={{ border: '1px solid #C8C8CC' }}>
                    <TableCell colSpan={8}>
                      <Typography noWrap sx={{ margin: 'auto', textAlign: 'center' }}>
                        {isLoading ? (
                          <CircularProgress sx={{ color: '#C8C8CC' }} size={25} />
                        ) : (
                          'Empty Data'
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
      )}

      <ModalContainer
        Content={ContentFoodBatchModal}
        modalActive={modalActive}
        onClose={() => setModalActive(false)}
      />
    </>
  );
};

export default FoodBatchTab;
