import { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const RequireAuth = ({ children }) => {
  const { admin, checkAccessToken, accessToken } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        await checkAccessToken();
      } catch (error) {
        console.error(error);
      }
    };

    if (accessToken && !admin) bootstrapAsync();
  }, [accessToken, admin, checkAccessToken]);

  if (!accessToken) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (admin) {
    return children;
  }

  return null;
};

export default RequireAuth;
