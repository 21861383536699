import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { useState, useEffect } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { HeaderBar } from './shared';
import { finishReserved, getReservation } from '../api/reservation';
import dayjs from 'dayjs';

const FinishReservation = ({ isNavigationDrawerOpen, onMenuButtonClick }) => {
  const [reservations, setReservations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [idWillFinish, setIdWillFinish] = useState(0);
  const [nameWillFinish, setNameWillFinish] = useState('');

  const fetchApi = async () => {
    setIsLoading(true);
    try {
      const { data } = await getReservation();
      setReservations(data);
    } catch (error) {
      console.error(error);
      setReservations([]);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchApi();
  }, []);

  const statusList = {
    100: 'Pending',
    200: 'Reserved',
    201: 'Finished',
    300: 'Canceled',
    301: 'Admin Canceled',
    401: 'Verified',
    500: 'Failed'
  };

  const handleFinish = (id, name) => {
    setIdWillFinish(id);
    setNameWillFinish(name);
    setIsConfirm(true);
  };

  const handleCancelFinish = () => {
    setIsConfirm(false);
    setIdWillFinish(0);
    setNameWillFinish('');
  };

  const handleFinishFix = () => {
    const finshReservation = async () => {
      try {
        await finishReserved(idWillFinish);
        setIsConfirm(false);
        setIdWillFinish(0);
        setNameWillFinish('');
      } catch (error) {
        console.error(error);
      } finally {
        fetchApi();
        setIsConfirm(false);
        setIdWillFinish(0);
        setNameWillFinish('');
      }
    };

    finshReservation();
  };

  return (
    <>
      <HeaderBar
        isNavigationDrawerOpen={isNavigationDrawerOpen}
        onMenuButtonClick={onMenuButtonClick}
        title={`Finish Reservation`}
      />
      <Grid container item column={12}>
        {isLoading ? (
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: '200px' }}>
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12} container columns={12} spacing={2} sx={{ p: '0px 36px 80px 36px' }}>
            <Grid item xs={12}>
              <Grid container columns={5} sx={{ mt: '44px' }} spacing={3}>
                {reservations
                  .filter(
                    (item) =>
                      (item.reservations.status === 200 || item.reservations.status === 401) &&
                      item.reservations.statusPayment === 200 &&
                      item.reservations.table
                  )
                  .map((data, index) => {
                    let { reservations, name } = data;
                    let { id, status, table } = reservations;

                    // const time = dayjs(appointmentAt).format('DD/MM/YYYY HH:mm:ss');
                    return (
                      <Grid item xs={1} key={index}>
                        <Card sx={{ p: '14px' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 1
                            }}>
                            <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
                              {table}
                            </Typography>
                            <Typography>{name}</Typography>
                            <Typography
                              sx={[
                                status === 401 && {
                                  color: '#1976D2'
                                }
                              ]}>
                              {statusList[status]}
                            </Typography>
                          </Box>
                          <Box sx={{ mt: '20px', width: '100%' }}>
                            <Button
                              variant="contained"
                              color="success"
                              sx={{ width: '100%' }}
                              onClick={() => handleFinish(id, table)}>
                              Finish Reservation
                            </Button>
                          </Box>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Dialog open={isConfirm} onClose={handleCancelFinish}>
        <DialogTitle align="center">Finish Reservation</DialogTitle>
        <DialogContent dividers>
          <Box sx={{ minWidth: '400px' }}>
            <Typography align="center">
              {' '}
              Are you sure want to finish table {nameWillFinish} ?
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              gap: '10px',
              mt: '40px'
            }}>
            <Button variant="outlined" color="error" onClick={handleCancelFinish}>
              No
            </Button>
            <Button variant="contained" color="success" onClick={handleFinishFix}>
              Yes
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default FinishReservation;
