import { Box, Modal } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  // p: 4,
  pt:4,
  pb:4,
  width: 350,
  background: '#FFFFFF',
  borderRadius: '10px',
  outline: 'none'
};

const ModalContainer = ({ modalActive, onClose, Content }) => {
  return (
    <Modal
      open={modalActive}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Content />
      </Box>
    </Modal>
  );
};

export default ModalContainer;
