import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { HeaderBar } from './shared';
import CachedIcon from '@mui/icons-material/Cached';
import debounce from 'lodash.debounce';
import {
  deleteInvoiceWrongOrder,
  getFinishInvoice,
  getProductChange,
  getWrongOrder,
  saveInvoiceWrongOrder
} from '../api/wrongOrder';
import { Delete, NoteAdd } from '@mui/icons-material';
import CustomSelectSearch from './shared/CustomSelectSearch';

const WrongOrder = ({ isNavigationDrawerOpen, onMenuButtonClick, admin }) => {
  const [allWrongOrder, setAllWrongOrder] = useState([]);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [isHandleLoading, setIsHandleLoading] = useState(false);
  const [alert, setAlert] = useState({
    severity: 'success',
    message: '',
    open: false
  });
  const [field, setField] = useState({
    invoice: '',
    order: '',
    product: '',
    detail: '',
    quantity: ''
  });

  const handleSubmitWrongOrder = () => {
    if (field.order && field.product && field.detail && field.quantity) {
      const makeInvoiceWrongOrder = async () => {
        setIsHandleLoading(true);
        try {
          const invoiceOrderId = field.order.value;
          const productId = field.product.value;
          const detail = field.detail;
          const quantity = field.quantity;
          await saveInvoiceWrongOrder({ invoiceOrderId, productId, detail, quantity });
          setAlert({
            message: 'Wrong order created!',
            severity: 'success',
            open: true
          });
          setIsOpenAdd(false);
        } catch (error) {
          setAlert({
            message: 'Failed when create a wrong order!',
            severity: 'warning',
            open: true
          });
          console.error(error);
        } finally {
          setField({
            invoice: '',
            order: '',
            product: '',
            detail: '',
            quantity: ''
          });

          fetchWrongOrderApi();
          setIsHandleLoading(false);
        }
      };
      makeInvoiceWrongOrder();
      doActivity();
    } else {
      setAlert({
        message: 'Please complete all the nessesary data',
        severity: 'warning',
        open: true
      });
    }
  };

  const handleDeleteWrongOrder = (id) => {
    const deleteWrongOrder = async () => {
      setIsHandleLoading(true);
      try {
        await deleteInvoiceWrongOrder({ id });
        setAlert({
          message: 'Wrong order deleted!',
          severity: 'success',
          open: true
        });
      } catch (error) {
        setAlert({
          message: 'Cannot delete wrong order! 1 day has pass since created date!',
          severity: 'warning',
          open: true
        });
        console.error(error);
      } finally {
        fetchWrongOrderApi();
        setIsHandleLoading(false);
      }
    };
    deleteWrongOrder();
    doActivity();
  };

  const fetchWrongOrderApi = async () => {
    setIsHandleLoading(true);
    try {
      const { data } = await getWrongOrder();
      const invoiceOrder = data.invoiceWrongOrders;
      setAllWrongOrder(invoiceOrder);
    } catch (error) {
      console.error();
    } finally {
      setIsHandleLoading(false);
    }
  };

  const fetchInvoice = async (keyword = null) => {
    try {
      let { data } = await getFinishInvoice({
        keyword
      });

      let mapped = data.map((invoice) => ({
        label: `${invoice.id} - ${invoice.customerName}`,
        value: invoice.id,
        orders: invoice.orders
      }));

      setInvoices(mapped);
      return mapped;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchOrders = async (keyword = null) => {
    try {
      return orders.map((order) => ({
        label: `${order.id} - ${order.name}`,
        value: order.id
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchProductChange = async (keyword = null) => {
    try {
      let { data } = await getProductChange({
        keyword
      });

      let mapped = data.map((product) => ({
        label: product.name,
        value: product.id
      }));

      setProducts(mapped);
      return mapped;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchWrongOrderApi();
  }, []);

  const handleOpened = () => {
    setIsOpenAdd(true);
  };

  // !---------------- Activity ---------------------------

  const [isAnyActivity, setIsAnyActivity] = useState(false);

  const activityDebounce = useRef(
    debounce(() => {
      setIsAnyActivity(true);
    }, 5000)
  ).current;

  const doActivity = () => {
    setIsAnyActivity(false);
    activityDebounce();
  };

  useEffect(() => {
    doActivity();
  }, []);

  useEffect(() => {
    if (isAnyActivity) {
      const interval = setInterval(() => {
        fetchWrongOrderApi();
      }, 90000);

      return () => clearInterval(interval);
    }
  }, [isAnyActivity]);

  return (
    <>
      <HeaderBar
        isNavigationDrawerOpen={isNavigationDrawerOpen}
        onMenuButtonClick={onMenuButtonClick}
        title={`Wrong Order`}
      />

      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={alert.open}>
        <Alert
          severity={alert.severity}
          onClose={() =>
            setAlert({
              message: '',
              open: false,
              severity: 'success'
            })
          }
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={alert.open}>
          {alert.message}
        </Alert>
      </Snackbar>

      <Dialog
        open={isOpenAdd}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={() => {
          setIsOpenAdd(false);
        }}>
        <DialogTitle>Add Wrong Order</DialogTitle>
        <DialogContent dividers>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <CustomSelectSearch
              fullWidth
              placeholder="Search Invoice"
              loadOptions={(keyword) => fetchInvoice(keyword)}
              value={field.invoice}
              onChange={(invoice) => {
                const mappedOrder = invoice.orders.map((order) => ({
                  label: order.name,
                  value: order.id
                }));

                setOrders(mappedOrder);
                const order = '';
                setField((prevState) => ({ ...prevState, invoice, order }));
              }}
              defaultOptions={invoices}
            />
          </Grid>

          <Grid item xs={12} sx={{ mb: 2 }}>
            <CustomSelectSearch
              fullWidth
              placeholder="Select Order"
              loadOptions={(keyword) => fetchOrders(keyword)}
              value={field.order}
              onChange={async (order) => {
                setField((prevState) => ({ ...prevState, order }));
              }}
              defaultOptions={orders}
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <CustomSelectSearch
              fullWidth
              placeholder="Select Product Wrong Into"
              loadOptions={(keyword) => fetchProductChange(keyword)}
              value={field.product}
              onChange={async (product) => {
                setField((prevState) => ({ ...prevState, product }));
              }}
              defaultOptions={products}
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <TextField
              id="reason"
              size="small"
              type="text"
              value={field.detail}
              fullWidth
              InputProps={{
                inputProps: { min: 1, placeholder: 'Description' },
                sx: { borderRadius: 1 }
              }}
              onChange={(event) => {
                const detail = event.target.value;
                setField((prevState) => ({ ...prevState, detail }));
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <TextField
              id="quantity"
              size="small"
              type="number"
              value={field.quantity}
              fullWidth
              InputProps={{
                inputProps: { min: 1, placeholder: 'Quantity' },
                sx: { borderRadius: 1 }
              }}
              onChange={(event) => {
                const quantity = event.target.value;
                setField((prevState) => ({ ...prevState, quantity }));
              }}
            />
          </Grid>
          <Box sx={{ display: 'flex' }}>
            <Button
              color="primary"
              sx={{ ml: 'auto' }}
              variant="contained"
              onClick={handleSubmitWrongOrder}>
              Submit
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Box sx={{ mt: '40px', width: '100%', p: '40px', position: 'relative' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button
            sx={{ mb: '10px' }}
            onClick={() => {
              fetchWrongOrderApi();
              doActivity();
            }}>
            <CachedIcon />
          </Button>
          {admin.permissions.includes('add-wrong-order') && (
            <Button
              sx={{
                mb: '10px',
                ml: 'auto'
              }}
              onClick={handleOpened}>
              <NoteAdd />
              <Typography>Add Wrong Order</Typography>
            </Button>
          )}
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ bgcolor: '#363740', color: 'white' }} aria-label="sticky table">
              <TableRow>
                <TableCell sx={{ color: 'white', width: '54px' }}>No.</TableCell>
                <TableCell sx={{ color: 'white' }}>Invoice</TableCell>
                <TableCell sx={{ color: 'white' }}>Product</TableCell>
                <TableCell sx={{ color: 'white' }}>Price</TableCell>
                <TableCell sx={{ color: 'white' }}>Wrong Product</TableCell>
                <TableCell sx={{ color: 'white' }}>Quantity</TableCell>
                <TableCell sx={{ color: 'white' }}>Detail</TableCell>
                <TableCell sx={{ color: 'white' }}>Date</TableCell>
                <TableCell sx={{ color: 'white' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allWrongOrder.map((wrongOrder, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ width: '54px' }}>{index + 1}</TableCell>
                  <TableCell>{wrongOrder?.order?.invoiceId}</TableCell>
                  <TableCell>{wrongOrder?.order?.product?.name}</TableCell>
                  <TableCell>
                    Rp{wrongOrder?.order?.product?.price.toLocaleString('id-ID')}
                  </TableCell>
                  <TableCell>{wrongOrder?.product?.name ?? '-'}</TableCell>
                  <TableCell>{wrongOrder?.quantity ?? '-'}</TableCell>
                  <TableCell>{wrongOrder?.detail ?? '-'}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <Typography>{dayjs(wrongOrder?.createdAt).format('DD/MMM/YY')}</Typography>
                      <Typography sx={{ color: 'blue' }}>
                        {dayjs(wrongOrder?.createdAt).format('HH:mm')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {admin.permissions.includes('delete-wrong-order') ? (
                      <Button onClick={() => handleDeleteWrongOrder(wrongOrder?.id)}>
                        <Delete style={{ color: 'red' }} />
                      </Button>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {isHandleLoading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: 'white',
            opacity: 0.5
          }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};
export default WrongOrder;
