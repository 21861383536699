import { kitchenAxios } from '../utils/api';

export const getWrongOrder = () => kitchenAxios.get('invoice/wrong-orders?page=1&page_size=999999');
export const getFinishInvoice = ({ keyword }) =>
  kitchenAxios.get('invoices/list', {
    params: {
      invoiceId: keyword
    }
  });

export const getProductChange = ({ keyword }) =>
  kitchenAxios.get('product', {
    params: {
      keyword
    }
  });

export const saveInvoiceWrongOrder = ({ invoiceOrderId, productId, quantity, detail }) =>
  kitchenAxios.post('invoice/wrong-orders', {
    invoiceOrderId,
    productId,
    quantity,
    detail
  });

export const deleteInvoiceWrongOrder = ({ id }) =>
  kitchenAxios.delete(`invoice/wrong-orders/${id}`);
