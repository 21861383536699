import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { NavigationDrawer } from './components';

const Layout = ({ isDrawerOpen, routes, onMinimizeButtonClick }) => (
  <Box sx={{ display: 'flex' }}>
    <NavigationDrawer
      isOpen={isDrawerOpen}
      onMinimizeButtonClick={onMinimizeButtonClick}
      routes={routes}
    />
    <Outlet />
  </Box>
);

// Layout.propTypes = {
//   isDrawerOpen: PropTypes.bool.isRequired,
//   routeGroups: PropTypes.array.isRequired,
//   onMinimizeButtonClick: PropTypes.func.isRequired,
// };

export default Layout;
