import React, { useContext, useEffect, useState } from 'react';

import {
  AssignmentInd as AssignmentIndIcon,
  Beenhere,
  Cancel,
  CookieRounded,
  Dashboard as DashboardIcon,
  MenuBook,
  RestaurantMenu as RestaurantMenuIcon,
  RoomService as RoomServiceIcon,
  TableBarRounded,
  TableRestaurant as TableRestaurantIcon,
  TakeoutDining as TakeoutDiningIcon
} from '@mui/icons-material';
import { Route, Routes } from 'react-router-dom';
import { getAllTables, setTableFree } from './api/tableManagement';
import {
  CooksDisplay,
  FinishTray,
  Login,
  Menu,
  MenuDetail,
  NotFound,
  Overview,
  RequireAuth,
  TableList
} from './components';
import dayjs from 'dayjs';
import { AuthContext } from './contexts/AuthContext';
import Layout from './Layout';
import Catering from './components/Catering';
import CateringSchedule from './components/CateringSchedule';
import CateringScheduleDetail from './components/CateringScheduleDetail';
import Snackbar from './components/shared/Snackbar';
import useSocketIO from './hooks/useSocketIO';
import Reservation from './components/Reservation';
import EventIcon from '@mui/icons-material/Event';
import TableReservation from './components/TableReservation';
import FinishReservation from './components/FinishReservation';
import WrongOrder from './components/WrongOrder';
import CancelRequest from './components/CancelRequest';

const App = () => {
  // useTimeout(() => location.reload(), 78000);

  const { socket, triggeredFetch, setTriggeredFetch, setNewOrdersCount, newOrdersCount } =
    useSocketIO();

  const { admin } = useContext(AuthContext);

  const [newOrder, setNewOrder] = useState(null);

  const [tables, setTables] = useState([]);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: '',
    type: 'success'
  });

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, isOpen: false, message: '' });
  };

  tables.map((table) => {
    if (table.occupied) {
      // console.log(table);

      let invoiceStatuses = table.invoices.map((invoice) => invoice.status);
      let invoiceUpdatedAt = table.invoices.map((invoice) => invoice.updatedAt);
      if (table.invoices.length > 0) {
        let isCleared = !(invoiceStatuses.includes(100) || invoiceStatuses.includes(200));
        // console.log(invoiceStatuses);
        if (isCleared) {
          if (dayjs().diff(dayjs(invoiceUpdatedAt[0]), 'milliseconds') > 3600000) {
            setTableFree(table.id);
          } else {
            setTimeout(
              () => setTableFree(table.id),
              3600000 - dayjs().diff(invoiceUpdatedAt[0], 'milliseconds')
            );
          }
        }
      }
    }
  });

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const { data } = await getAllTables();
        setTables(data);
      } catch (error) {
        console.error(error);
      }
    };

    bootstrapAsync();
  }, [triggeredFetch]);

  useEffect(() => {
    socket?.on('overview', (data) => {
      setNewOrder(data?.content?.order);
      setTriggeredFetch((prevState) => prevState + 1);
      data?.content?.overview === 'neworder' && setNewOrdersCount(newOrdersCount + 1);
      data.content?.overview === 'updateorder' && location.reload();
    });

    socket?.on('finishtray', (data) => {
      setTriggeredFetch((prevState) => prevState + 1);
    });

    return () => {
      socket?.disconnect();
    };
  }, [socket]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const returnSuccess = (message = null) => {
    setSnackbar({
      ...snackbar,
      isOpen: true,
      message:  message !== null ? message : 'Transaction success',
      type: 'success'
    });
  };

  const returnError = (message = null) => {
    return setSnackbar({
      ...snackbar,
      isOpen: true,
      message: message !== null ? message : 'Something went wrong',
      type: 'error'
    });
  };

  const snackbarAction = {
    returnSuccess,
    returnError
  };

  const handleDrawerOpen = () => setIsDrawerOpen(true);
  const handleDrawerClose = () => setIsDrawerOpen(false);

  const role = localStorage.getItem('role');

  const ROUTES = [
    {
      name: 'Overview',
      path: '/overview',
      component: (
        <Overview
          isNavigationDrawerOpen={isDrawerOpen}
          onMenuButtonClick={handleDrawerOpen}
          newOrder={newOrder}
        />
      ),
      icon: <DashboardIcon />,
      showOnNavDrawer: admin && admin.permissions.includes('view-overview')
    },
    {
      name: 'Finish Tray',
      path: '/finish-tray',
      component: (
        <FinishTray isNavigationDrawerOpen={isDrawerOpen} onMenuButtonClick={handleDrawerOpen} />
      ),
      icon: <RoomServiceIcon />,
      showOnNavDrawer: admin && admin.permissions.includes('view-finish-tray')
    },

    {
      name: 'Cooks Display',
      path: '/cooks-display',
      component: (
        <CooksDisplay isNavigationDrawerOpen={isDrawerOpen} onMenuButtonClick={handleDrawerOpen} />
      ),
      icon: <AssignmentIndIcon />,
      showOnNavDrawer: admin && admin.permissions.includes('view-cook-display')
    },
    {
      name: 'Table List',
      path: '/table-list',
      component: (
        <TableList isNavigationDrawerOpen={isDrawerOpen} onMenuButtonClick={handleDrawerOpen} />
      ),
      icon: <TableRestaurantIcon />,
      showOnNavDrawer:
        admin && admin.permissions.includes('view-table-management') && role != 'Reservation'
    }, // {
    //   name: 'Report',
    //   path: '/report',
    //   component: <></>,
    //   icon: <AssessmentIcon />,
    //   showOnNavDrawer: true,
    // },
    {
      name: 'Menu',
      path: '/menu',
      component: (
        <Menu isNavigationDrawerOpen={isDrawerOpen} onMenuButtonClick={handleDrawerOpen} />
      ),
      icon: <RestaurantMenuIcon />,
      showOnNavDrawer: admin && admin.permissions.includes('view-menu')
    },
    {
      name: 'Menu Catering',
      path: '/catering-schedule',
      component: (
        <CateringSchedule
          isNavigationDrawerOpen={isDrawerOpen}
          onMenuButtonClick={handleDrawerOpen}
          snackbarAction={snackbarAction}
        />
      ),
      icon: <MenuBook />,
      showOnNavDrawer: admin && admin.permissions.includes('view-menu')
    },
    {
      name: 'Catering',
      path: '/catering',
      component: (
        <Catering
          isNavigationDrawerOpen={isDrawerOpen}
          onMenuButtonClick={handleDrawerOpen}
          snackbarAction={snackbarAction}
        />
      ),
      icon: <CookieRounded />,
      showOnNavDrawer: admin && admin.permissions.includes('view-menu')
    },
    {
      name: 'Wrong Order',
      path: '/wrong-order',
      component: (
        <WrongOrder
          isNavigationDrawerOpen={isDrawerOpen}
          onMenuButtonClick={handleDrawerOpen}
          snackbarAction={snackbarAction}
          admin={admin}
        />
      ),
      icon: <Cancel />,
      showOnNavDrawer: admin && admin.permissions.includes('view-wrong-order')
    },
    {
      name: 'Cancel Request',
      path: '/cancel-request',
      component: (
        <CancelRequest
          isNavigationDrawerOpen={isDrawerOpen}
          onMenuButtonClick={handleDrawerOpen}
          snackbarAction={snackbarAction}
          admin={admin}
        />
      ),
      icon: <Beenhere />,
      showOnNavDrawer: admin && admin.permissions.includes('view-cancel-order')
    },
    // {
    //   name: 'Reservation',
    //   path: '/reservation',
    //   component: <></>,
    //   icon: <EventIcon />,
    //   showOnNavDrawer: true,
    // },
    {
      name: 'Menu Detail',
      path: '/menu/:categoryId',
      component: (
        <MenuDetail isNavigationDrawerOpen={isDrawerOpen} onMenuButtonClick={handleDrawerOpen} />
      ),
      icon: null,
      showOnNavDrawer: false
    },
    {
      name: 'Menu Catering Detail',
      path: '/catering-schedule/:menuId',
      component: (
        <CateringScheduleDetail
          isNavigationDrawerOpen={isDrawerOpen}
          onMenuButtonClick={handleDrawerOpen}
          snackbarAction={snackbarAction}
        />
      ),
      icon: null,
      showOnNavDrawer: false
    },
    {
      name: 'Reservation',
      path: '/reservation',
      component: (
        <Reservation
          isNavigationDrawerOpen={isDrawerOpen}
          onMenuButtonClick={handleDrawerOpen}
          newOrder={newOrder}
        />
      ),
      icon: <EventIcon />,
      showOnNavDrawer: role === 'Reservation'
    },
    {
      name: 'Finish Reservation',
      path: '/finishreservation',
      component: (
        <FinishReservation
          isNavigationDrawerOpen={isDrawerOpen}
          onMenuButtonClick={handleDrawerOpen}
          newOrder={newOrder}
        />
      ),
      icon: <TakeoutDiningIcon />,
      showOnNavDrawer: role === 'Reservation'
    },
    {
      name: 'Table Raservation',
      path: '/tablereservation',
      component: (
        <TableReservation
          isNavigationDrawerOpen={isDrawerOpen}
          onMenuButtonClick={handleDrawerOpen}
          newOrder={newOrder}
          snackbarAction={snackbarAction}
        />
      ),
      icon: <TableBarRounded />,
      showOnNavDrawer: role === 'Reservation'
    }
  ];

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          element={
            <RequireAuth>
              <Layout
                isDrawerOpen={isDrawerOpen}
                routes={ROUTES}
                onMinimizeButtonClick={handleDrawerClose}
              />
            </RequireAuth>
          }>
          {ROUTES.map((route, routeIndex) => (
            <Route path={route.path} element={route.component} key={routeIndex} />
          ))}
        </Route>
        <Route path="/*" element={<NotFound />} />
      </Routes>

      {snackbar.isOpen && (
        <Snackbar
          open={snackbar.isOpen}
          message={snackbar.message}
          type={snackbar.type}
          handleCloseSnackbar={handleCloseSnackbar}
        />
      )}
    </>
  );
};

export default App;
