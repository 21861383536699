/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
import { Add as AddIcon, Delete as DeleteIcon, Print } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { DrawerHeader, HeaderBar } from './shared';
import { useEffect, useState } from 'react';
import { addCook, getCookDisplay, getCooks, removeCook } from '../api/cookDisplay';
import { getTimeDifference } from '../utils/time';

const ChefSearchBar = () => {
  const [cooks, setCooks] = useState([]);
  const [selectedCook, setSelectedCook] = useState(null);

  const handleSelectedCookChange = (event, value) => setSelectedCook(value);
  const handleAddButtonClick = async () => {
    try {
      await addCook(selectedCook.staffId);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const { data } = await getCooks();
        setCooks(data);
      } catch (error) {
        console.error(error);
      }
    };

    bootstrapAsync();
  }, []);

  return (
    <Stack direction="row" spacing={2.5}>
      <Autocomplete
        disablePortal
        sx={{ width: '20rem' }}
        options={cooks}
        getOptionLabel={(option) => option.name}
        value={selectedCook}
        isOptionEqualToValue={(option, value) => option.staffId === value.staffId}
        onChange={handleSelectedCookChange}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ bgcolor: 'grey.200' }}
            placeholder="Search"
            InputProps={{
              ...params.InputProps,
              sx: {
                typography: 'h6',
                height: '3.125rem'
              },
              endAdornment: {
                ...params.InputProps.endAdornment,
                props: {
                  ...params.InputProps.endAdornment.props,
                  sx: {
                    top: 'calc(50% - 16px)'
                  }
                }
              }
            }}
            inputProps={{
              ...params.inputProps,
              sx: {
                py: '0 !important'
              }
            }}
          />
        )}
      />
      <Button
        variant="contained"
        color="warning"
        sx={{
          width: '3.125rem',
          height: '3.125rem',
          minWidth: '3.125rem'
        }}
        onClick={handleAddButtonClick}>
        <AddIcon />
      </Button>
    </Stack>
  );
};

const ChefHeaderBar = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      mt: 5.875,
      mx: 4,
      alignItems: 'center'
    }}>
    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
      Chef On Site
    </Typography>
    <ChefSearchBar />
  </Box>
);

const OrderCard = ({ invoice }) => {
  const [timeElapsed, setTimeElapsed] = useState('00:00:00');

  const theme = useTheme();

  useEffect(() => {
    const timeElapsedIntervalId = setInterval(() => {
      setTimeElapsed(getTimeDifference(invoice.updatedAt));
    }, 1000);

    return () => clearInterval(timeElapsedIntervalId);
  }, [invoice.updatedAt]);

  return (
    <Paper
      sx={{
        pb: 2,
        bgcolor: Boolean(invoice.statuses.statusPrint) ? `${theme.palette.warning.main}4D` : '#fff'
      }}>
      {/* <Box sx={{ mx: 1, mt: 1.5 }}>
        <img
          src={invoice.order.product.imageUrl}
          style={{
            width: '100%',
            borderRadius: 10,
            objectFit: 'cover',
          }}
        />
      </Box> */}
      <Box sx={{ mx: 2, mt: 2 }}>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
          <Typography
            variant="h6"
            color="info.dark"
            sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
            {invoice.orderType === 1 ? 'Dine In' : 'Take Away'}
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '1rem'
            }}
            color={
              parseInt(timeElapsed.split(':')[0]) > 0 ||
              parseInt(timeElapsed.split(':')[1]) >=
                parseInt(process.env.REACT_APP_TIME_ELAPSED_LIMIT_IN_MINUTE)
                ? 'error'
                : null
            }>
            {timeElapsed}
          </Typography>
        </Box>

        <Typography sx={{ fontWeight: 'bold' }}>
          {invoice.order.quantity}x{' '}
          <Box component="span" sx={{ textTransform: 'uppercase' }}>
            {invoice.order.product.name}
          </Box>
        </Typography>
        <Typography sx={{ textTransform: 'uppercase' }}>{invoice.order.note}</Typography>
        <Box sx={{ mt: 2.125 }}>
          {invoice.statuses.ongoing !== 0 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 1
              }}>
              <Typography
                sx={{
                  fontWeight: 'bold'
                }}>
                {`ONGOING: ${invoice.statuses.ongoing} item(s)`}
              </Typography>
              {
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      window.open(
                        `https://kitchen.enak.id/print-menu.php?IDorder=${invoice.order?.id}&IDinvoice=${invoice.id}`
                      );
                    }}>
                    <Print />
                  </IconButton>
                  <Typography>({invoice.statuses?.statusPrint})</Typography>
                </Box>
              }
            </Box>
          )}
          {invoice.statuses.recook !== 0 && (
            <Typography
              color="error"
              sx={{
                fontWeight: 'bold'
              }}>{`RECOOK: ${invoice.statuses.recook} item(s)`}</Typography>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

const ChefColumn = ({ chef }) => {
  const handleDeleteButtonClick = async () => {
    try {
      await removeCook(chef.staff.id);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Stack
      sx={{ width: { xs: '15rem', lg: '20.5rem' }, flexShrink: 0 }}
      spacing={chef.invoices.length === 0 ? 2.5 : 3.5}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          {chef.staff.name}
        </Typography>
        {chef.invoices.length === 0 && (
          <IconButton onClick={handleDeleteButtonClick}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
      <Stack
        spacing={3}
        sx={{
          border: '1px',
          borderStyle: 'dashed dashed none',
          px: { xs: 1.5, lg: 3 },
          py: { xs: 1.5, lg: 3 },
          flex: 1,
          overflow: 'auto'
        }}>
        {chef.invoices.map((invoice) => (
          <OrderCard key={invoice.order.id} invoice={invoice} />
        ))}
      </Stack>
    </Stack>
  );
};

const CooksDisplay = ({ isNavigationDrawerOpen, onMenuButtonClick }) => {
  const [onSiteChefs, setOnSiteChefs] = useState([]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const { data } = await getCookDisplay();
        setOnSiteChefs(data);
      } catch (error) {
        console.error(error);
      }
    };

    bootstrapAsync();
  }, []);

  return (
    <>
      <HeaderBar
        isNavigationDrawerOpen={isNavigationDrawerOpen}
        onMenuButtonClick={onMenuButtonClick}
        title="Cooks Display"
      />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          flexGrow: 1,
          overflow: 'hidden'
        }}>
        <DrawerHeader />
        <ChefHeaderBar />
        <Stack direction="row" spacing={2.5} sx={{ overflow: 'auto', mt: 3.5, px: 4.5, flex: 1 }}>
          {onSiteChefs.map((onSiteChef) => (
            <ChefColumn chef={onSiteChef} key={onSiteChef.staff.id} />
          ))}
        </Stack>
      </Box>
    </>
  );
};

// const ORDER_TYPE = PropTypes.shape({
//   id: PropTypes.number.isRequired,
//   type: PropTypes.string.isRequired,
//   qty: PropTypes.number.isRequired,
//   item: PropTypes.shape({
//     id: PropTypes.number.isRequired,
//     name: PropTypes.string.isRequired,
//     imageUrl: PropTypes.string.isRequired,
//   }).isRequired,
//   notes: PropTypes.string.isRequired,
//   createdAt: PropTypes.string.isRequired,
// }).isRequired;

// const ON_SITE_CHEF_TYPE = PropTypes.shape({
//   id: PropTypes.number.isRequired,
//   name: PropTypes.string.isRequired,
//   orders: PropTypes.arrayOf(ORDER_TYPE).isRequired,
// }).isRequired;

// NOTE:
// const CHEF_TYPE = PropTypes.shape({
//   id: PropTypes.number.isRequired,
//   name: PropTypes.string.isRequired,
// }).isRequired;

// OrderCard.propTypes = {
//   order: ORDER_TYPE,
// };

// ChefColumn.propTypes = {
//   chef: ON_SITE_CHEF_TYPE,
// };

// CooksDisplay.propTypes = {
//   isNavigationDrawerOpen: PropTypes.bool.isRequired,
//   onMenuButtonClick: PropTypes.func.isRequired,
// };

export default CooksDisplay;
