import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@mui/material';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { renderRequired } from '../emptyData';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const AddPlan = ({ dataType, onBack, snackbarAction, menuId }) => {
  const [type, setType] = useState([menuId]);
  const [banner, setBanner] = useState(null);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      usage_quantity: 0,
      price: 0,
      priceBefore: 0,
      terms_and_conditions: '',
      types: [menuId]
    }
  });

  const validateFinalPrice = (price) => {
    return price < getValues('priceBefore');
  };

  const validateDiscountPrice = (price) => {
    return price > getValues('price');
  };

  const DropzoneImage = () => {
    const [isImageUploaded, setIsImageUploaded] = useState(false);

    const onDrop = useCallback((acceptedFiles) => {
      const data = new FormData();
      data.append('image', acceptedFiles[0]);

      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/caterings/plans/image`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
        })
        .then((res) => {
          const {
            data: {
              data: { image_url }
            }
          } = res;
          setBanner(image_url);
        })
        .catch(() => {
          snackbarAction.returnError();
        });
      setIsImageUploaded(true);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
      <>
        <div
          {...getRootProps()}
          style={{
            border: '1px solid #CCCCCC',
            borderRadius: 8,
            width: '100%',
            height: '100%'
          }}>
          <input {...getInputProps()} />
          {banner !== null && (
            <img
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
                borderRadius: 8
              }}
              src={banner}
              alt="image"
            />
          )}
        </div>
      </>
    );
  };

  const onSubmit = async (data) => {
    const formData = new FormData();

    const { name, price, priceBefore, usage_quantity, types, terms_and_conditions } = data;

    types.forEach((item) => {
      formData.append('types[]', item);
    });

    formData.append('name', name);
    formData.append('image_url', banner);
    formData.append('final_price', price);
    formData.append('original_price', priceBefore);
    formData.append('usage_quantity', usage_quantity);
    formData.append('terms_and_conditions', terms_and_conditions);

    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/caterings/plans`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
        })
        .then(() => {
          snackbarAction.returnSuccess();
          setTimeout(onBack(), 3000);
        });
    } catch (e) {
      snackbarAction.returnError();
    }
  };

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    const typeSelected = typeof value === 'string' ? value.split(',') : value;
    setType(typeSelected);
    setValue('types', typeSelected);
  };
  return (
    <>
      <div className="display-flex" style={{ cursor: 'pointer' }} onClick={() => onBack()}>
        <ArrowBackIos />
        <span style={{ fontSize: '16px' }}>Add Plan</span>
      </div>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card sx={{ mt: 2, border: 'none', boxShadow: 'none' }}>
            <CardContent>
              <Typography variant="h5" sx={{ color: '#252733', fontWeight: 700 }}>
                Catering Settings
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ border: 'none', boxShadow: 'none' }}>
            <CardContent>
              <div className="display-flex-space-between cursor-pointer">
                <div>
                  <p style={{ fontSize: '16px', margin: 0 }}>Info</p>
                  <p style={{ fontSize: '12px', margin: 0 }}>Upload title & banner for catering</p>
                </div>
                <ArrowForwardIos
                  sx={{
                    fontSize: '25px',
                    marginTop: '5px',
                    cursor: 'pointer'
                  }}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={8}>
          <Card
            sx={{
              border: 'none',
              boxShadow: 'none'
            }}>
            <CardContent>
              <Controller
                name={'name'}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <div style={{ marginBottom: '2vh' }}>
                    <p className="form-label">Title</p>
                    <TextField
                      error={errors?.name}
                      onChange={onChange}
                      value={value}
                      placeholder="Example: Paket 3 hari bersama"
                      sx={{ width: '100%' }}
                      size="small"
                    />
                    {errors?.name && renderRequired()}
                  </div>
                )}
              />

              <p className="form-label" style={{ marginBottom: '1.5vh' }}>
                {' '}
                Upload Banner
              </p>
              <Grid container spacing={{ xs: 2, md: 3 }} sx={{ overflow: 'auto' }}>
                <Grid item xs={6} sm={6} md={6}>
                  <DropzoneImage />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <p>Upload banner</p>
                  <ul>
                    <li>
                      Format file JPG, JPEG, PNG <br /> (Rasio 2:1, min. 360 x 360px, maks. 2MB).
                    </li>
                    <li>Rasio bidang safe area 1:2,5.</li>
                  </ul>
                </Grid>
              </Grid>
              <div className="display-flex" style={{ margin: '1vh 0' }}>
                <div>
                  <Controller
                    name={'price'}
                    control={control}
                    rules={{ required: true, min: 1, validate: validateFinalPrice }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <p className="form-label">Final Price</p>
                        <TextField
                          error={errors?.price}
                          onChange={onChange}
                          value={value}
                          placeholder="Catering Price"
                          sx={{ width: '100%' }}
                          size="small"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                        />
                        {errors?.price &&
                          renderRequired(
                            errors?.price?.type === 'validate'
                              ? 'Final price should be lower'
                              : 'required'
                          )}
                      </div>
                    )}
                  />
                </div>
                <div style={{ marginLeft: '1vw' }}>
                  <Controller
                    name={'priceBefore'}
                    control={control}
                    rules={{ required: true, min: 1, validate: validateDiscountPrice }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <p className="form-label">Price Before Discount</p>
                        <TextField
                          error={errors?.priceBefore}
                          onChange={onChange}
                          value={value}
                          placeholder="Catering Price"
                          sx={{ width: '100%' }}
                          size="small"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                        />
                        {errors?.priceBefore &&
                          renderRequired(
                            errors?.priceBefore?.type === 'validate'
                              ? 'Before price should be higher'
                              : 'required'
                          )}
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className="display-flex" style={{ margin: '1vh 0' }}>
                <div>
                  <Controller
                    name={'usage_quantity'}
                    control={control}
                    rules={{ required: true, min: 1 }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <p className="form-label">Number of Food</p>
                        <TextField
                          error={errors?.usage_quantity}
                          onChange={onChange}
                          value={value}
                          placeholder="Number of Food"
                          sx={{ width: '100%' }}
                          size="small"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                        />
                        {errors?.usage_quantity && renderRequired()}
                      </div>
                    )}
                  />
                </div>
                <FormControl sx={{ width: '31%', ml: 2 }} size="small">
                  <p className="form-label">Schedule</p>
                  <Select
                    multiple
                    displayEmpty
                    value={type}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => {
                      const tempSelected = [];
                      selected?.map((item) =>
                        tempSelected.push(dataType?.find((x) => x.id === item)?.name)
                      );
                      if (selected.length === 0) {
                        return <em>Select One</em>;
                      }
                      return tempSelected.join(', ');
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                    placeholder="">
                    <MenuItem disabled value="">
                      <em>Schedule</em>
                    </MenuItem>
                    {dataType.map((item) => (
                      <MenuItem key={item?.name} value={item?.id}>
                        <Checkbox checked={type.indexOf(item?.id) > -1} />
                        <ListItemText primary={item?.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div style={{ marginBottom: '20px' }}>
                <Controller
                  name={'terms_and_conditions'}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <p
                        style={{
                          fontSize: '16px',
                          margin: '15px 0 1vh 0',
                          fontWeight: 700
                        }}>
                        Terms & Condition
                      </p>
                      <ReactQuill value={value} theme="snow" onChange={onChange} />
                      {errors?.terms_and_conditions && renderRequired()}
                    </>
                  )}
                />
              </div>
              <Button
                variant="contained"
                size="medium"
                sx={{ width: '15%' }}
                onClick={handleSubmit(onSubmit)}>
                Save
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPlan;
