import { useEffect, useState } from 'react';

import {
  Box,
  ButtonBase,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { DrawerHeader, HeaderBar } from './shared';
import { getCategories } from '../api/products';

const SearchField = ({ onChange }) => (
  <TextField
    placeholder="Search"
    sx={{
      bgcolor: 'grey.200',
      width: '20rem',
      ml: 4.625,
      mt: 6.5,
    }}
    InputProps={{
      sx: {
        typography: 'h6',
        height: '3.125rem',
      },
    }}
    onChange={onChange}
  />
);

const CategoryHeader = () => (
  <Typography variant="h5" sx={{ fontWeight: 'bold', mt: 7, ml: 4.625 }}>
    Category
  </Typography>
);

const CategoryCard = ({ category }) => (
  <ButtonBase
    component={RouterLink}
    to={`/menu/${category.id}`}
    state={category}
    sx={{
      width: 1,
      height: 1,
    }}
  >
    <Paper
      clickable="true"
      sx={{
        p: 3.75,
        width: 1,
        height: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        {category.name}
      </Typography>
    </Paper>
  </ButtonBase>
);

const CategoryGrid = ({ categories }) => (
  <Grid
    container
    spacing={2.625}
    sx={{ mt: 2.375, overflow: 'auto', pb: 4.625, px: 4.625 }}
  >
    {categories.map((category) => (
      <Grid item xs={3} lg={2.4} key={category.id}>
        <CategoryCard category={category} />
      </Grid>
    ))}
  </Grid>
);

const Menu = ({ isNavigationDrawerOpen, onMenuButtonClick }) => {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);

  const handleSearchFieldChange = (event) => {
    setFilteredCategories(
      categories.filter((category) =>
        new RegExp(
          `${event.target.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}`,
          'i'
        ).test(category.name)
      )
    );
  };

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const { data } = await getCategories();
        setCategories(data);
        setFilteredCategories(data);
      } catch (error) {
        console.error(error);
      }
    };

    bootstrapAsync();
  }, []);

  return (
    <>
      <HeaderBar
        isNavigationDrawerOpen={isNavigationDrawerOpen}
        onMenuButtonClick={onMenuButtonClick}
        title="Menu"
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <DrawerHeader />
        <SearchField onChange={handleSearchFieldChange} />
        <CategoryHeader />
        <CategoryGrid categories={filteredCategories} />
      </Box>
    </>
  );
};

const CATEGORY_TYPE = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
}).isRequired;

SearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

CategoryCard.propTypes = {
  category: CATEGORY_TYPE,
};

CategoryGrid.propTypes = {
  categories: PropTypes.arrayOf(CATEGORY_TYPE).isRequired,
};

Menu.propTypes = {
  isNavigationDrawerOpen: PropTypes.bool.isRequired,
  onMenuButtonClick: PropTypes.func.isRequired,
};

export default Menu;
