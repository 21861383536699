import { kitchenAxios } from '../utils/api';

export const getCategories = () => kitchenAxios.get('products/categories');

export const getCategory = ({ categoryId }) =>
  kitchenAxios.get(`product-categories/${categoryId}`);

export const getProductsByCategory = (id) =>
  kitchenAxios.get(`product-categories/${id}/products`);

export const changeProductStatus = (productId, status) =>
  kitchenAxios.put(`products/${productId}/set-status`, { status });
