import { FormControl, FormHelperText } from '@mui/material';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import React from 'react';
import AsyncSelect from 'react-select/async';

const CustomSelectSearch = ({
  fullWidth,
  loadOptions,
  placeholder = 'Search...',
  defaultOptions,
  isDisabled,
  onChange,
  value,
  cacheOptions = true,
  error,
  customStyles = ''
}) => {
  return (
    <FormControl fullWidth={fullWidth} error={error != null}>
      <AsyncSelect
        className="font-normal text-xs mt-1.5 p-3"
        placeholder={placeholder}
        defaultOptions={defaultOptions}
        loadOptions={AwesomeDebouncePromise(loadOptions, 1000)}
        cacheOptions={cacheOptions}
        isDisabled={isDisabled}
        onChange={onChange}
        value={value}
        styles={customStyles}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default CustomSelectSearch;
