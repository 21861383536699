/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/prop-types */
import { MoreHoriz as MoreHorizIcon, Print } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonBase,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import {
  assignCooks,
  cancel,
  cancelRequest,
  getAvailableCooks,
  getOverview,
  getOverviewBasic,
  onFinishRecook,
  ongoingRecook,
  paid,
  remind,
  setStatusCooked
} from '../api/orders';
import { getTimeDifference } from '../utils/time';
import { DrawerHeader, HeaderBar } from './shared';
import { formatNumberToCurrency } from '../utils/currency';
import { useNavigate } from 'react-router-dom';
import CustomSelectSearch from './shared/CustomSelectSearch';
import { getProductChange } from '../api/wrongOrder';

const OrderCard = ({ topRightComponent, bottomComponent, invoice, clickable, type }) => {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        flex: 1,
        bgcolor:
          type === 'waiting' && Boolean(invoice.statusPrint)
            ? `${theme.palette.warning.main}4D`
            : null
      }}
      color={invoice.order && invoice.order.orderStatuses[0].status === 103 ? 'error' : null}
      clickable={clickable}>
      {(invoice.note === 'Reservation' || invoice.onCancel === 1) && (
        <Box
          sx={{
            p: '4px 8px',
            bgcolor: !invoice.onCancel ? '#2196f3' : '#EF5350',
            borderRadius: '4px 4px 0px 0px',
            mb: '0px'
          }}>
          <Typography sx={{ color: 'white' }} align="center">
            {invoice.onCancel ? 'On Cancel' : 'Reservation'}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          flex: 1,
          p: invoice.note === 'Reservation' ? '2px 16px 16px 16px' : 2,
          bgcolor:
            type === 'waiting' && Boolean(invoice.statusPrint)
              ? `${theme.palette.warning.main}4D`
              : null
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <Typography variant="caption">
            {`${new Date(invoice.createdAt).toLocaleTimeString([], {
              hour12: false
            })} - `}
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              {invoice.orderType === 1 ? invoice.table?.name : 'Takeaway'}
            </Box>
          </Typography>
          <Box>{topRightComponent}</Box>
        </Box>
        <Typography sx={{ fontWeight: 600, mt: 1 }}>{invoice.customerName || '-'}</Typography>
        <Divider />
        <Box sx={{ mt: 1 }}>
          {invoice.orders ? (
            invoice.orders.map((order) => (
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                key={order.id}>
                <Typography variant="body1">
                  {order.quantity}x {order.product.name}
                </Typography>
                {(type === 'pending' || type === 'waiting') && (
                  <Typography variant="body1">
                    {formatNumberToCurrency(order.product.price)}
                  </Typography>
                )}
              </Box>
            ))
          ) : invoice.orderStatuses ? (
            invoice.orderStatuses.map((orderStatus) => (
              <Typography variant="body1" key={orderStatus.order.id}>
                {invoice.orderStatuses.quantity}x {orderStatus.order.product.name}
              </Typography>
            ))
          ) : invoice.order ? (
            <Typography variant="body1">
              {invoice.order.orderStatuses.length}x {invoice.order.product.name}
            </Typography>
          ) : (
            <Typography variant="body1">
              {invoice.orderStatus.order.quantity}x {invoice.orderStatus.order.product.name}
            </Typography>
          )}
          {(type === 'pending' || type === 'waiting') && (
            <Box sx={{ mt: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600 }}>Subtotal</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {formatNumberToCurrency(invoice.subtotal)}
                </Typography>
              </Box>
              {invoice.priceDetail.map((detail) => (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: detail.name === 'Discount' && 'red'
                  }}>
                  <Typography sx={{ fontWeight: 600, textTransform: 'capitalize' }}>
                    {detail.name === 'Pajak' ? 'Tax' : detail.name}
                  </Typography>
                  <Typography sx={{ fontWeight: 600, color: detail.name === 'Discount' && 'red' }}>
                    {detail.name === 'Discount' && '- '}
                    {formatNumberToCurrency(detail.amount)}
                  </Typography>
                </Box>
              ))}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 700 }}>Grand Total</Typography>
                <Typography sx={{ fontWeight: 700 }}>
                  {formatNumberToCurrency(invoice.grandTotal)}
                </Typography>
              </Box>
            </Box>
          )}
          {type === 'waiting' && (
            <Box
              sx={{
                display: 'flex',
                mt: 2,
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 1
              }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button
                  variant="outlined"
                  onClick={(event) => {
                    event.stopPropagation();
                    window.open(`https://kitchen.enak.id/print.php?IDinvoice=${invoice.invoiceId}`);
                  }}
                  endIcon={<Print />}>
                  Receipt
                </Button>
                <Button
                  variant="outlined"
                  onClick={(event) => {
                    event.stopPropagation();
                    window.open(
                      `https://kitchen.enak.id/kitchen.php?IDinvoice=${invoice.invoiceId}&stationId=${invoice.station?.id}`
                    );
                  }}
                  endIcon={<Print />}>
                  Print
                </Button>
              </Box>
              <Typography>({invoice.statusPrint})</Typography>
            </Box>
          )}
        </Box>
        {bottomComponent}
      </Box>
    </Paper>
  );
};

const OrderCardWithMenu = ({ invoice, type, fetchUlang }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleMenuOpen = (event) => setMenuAnchorEl(event.currentTarget);
  const handleMenuClose = () => setMenuAnchorEl(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reason, setReason] = useState('');

  const handleModalClose = () => setIsModalOpen(false);

  // awal approve
  const handleRemindClick = async () => {
    setMenuAnchorEl(null);
    try {
      await remind(invoice.invoiceId);
    } catch (error) {
      console.error(error);
    } finally {
      fetchUlang();
    }
  };

  const handleApproveClick = async () => {
    setMenuAnchorEl(null);
    try {
      await paid(invoice.invoiceId);
      // location.reload();
    } catch (error) {
      console.error(error);
    } finally {
      fetchUlang();
    }
  };
  const handleCancelClick = async () => {
    setMenuAnchorEl(null);
    try {
      await cancel(invoice.invoiceId);
      fetchUlang();
    } catch (error) {
      const { response } = error;

      response.status === 403 && setIsModalOpen(true);
      console.error(error);
    }
  };

  const handleRequestClick = async () => {
    const params = {
      invoiceId: invoice.invoiceId,
      reason
    };

    try {
      await cancelRequest(params);
    } catch (error) {
      console.error(error);
    } finally {
      setIsModalOpen(false);
      fetchUlang();
    }
  };

  return (
    <>
      <OrderCard
        invoice={invoice}
        type={type}
        topRightComponent={
          !invoice?.onCancel && (
            <>
              <IconButton onClick={handleMenuOpen}>
                <MoreHorizIcon />
              </IconButton>
              <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={handleApproveClick}>Approve</MenuItem>
                <MenuItem onClick={handleRemindClick}>Remind</MenuItem>
                <MenuItem onClick={handleCancelClick}>Cancel</MenuItem>
              </Menu>
            </>
          )
        }
      />
      <Dialog
        onClose={handleModalClose}
        open={isModalOpen}
        maxWidth={'sm'}
        PaperProps={{ sx: { width: '100%' } }}>
        <DialogTitle variant="h5" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
          Request Cancel Order
        </DialogTitle>
        <DialogContent
          sx={{
            p: 0,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
          }}>
          <Divider />
          <Box sx={{ overflow: 'auto', p: 4 }}>
            <Grid container direction="column" spacing={2.5}>
              <Grid container item sx={{ alignItems: 'center' }}>
                <Grid item xs={4}>
                  Invoice
                </Grid>
                <Grid item xs={8}>
                  {invoice.invoiceId}
                </Grid>
              </Grid>
              <Grid container item sx={{ alignItems: 'center' }}>
                <Grid item xs={4}>
                  Name
                </Grid>
                <Grid item xs={8}>
                  {invoice.customerName}
                </Grid>
              </Grid>

              <Grid container item sx={{ alignItems: 'center' }}>
                <Grid item xs={4}>
                  Reason
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    variant="outlined"
                    size="small"
                    sx={{ width: '100%' }}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 3.25, justifyContent: 'center' }}>
          <Button onClick={handleRequestClick} variant="contained">
            Request
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
// akhir approve

const OrderCardWithStopwatch = ({ bottomComponent, invoice, onClick, type }) => {
  const [timeElapsed, setTimeElapsed] = useState('00:00:00');

  useEffect(() => {
    const timeElapsedIntervalId = setInterval(() => {
      setTimeElapsed(getTimeDifference(invoice.updatedAt));
    }, 1000);

    return () => clearInterval(timeElapsedIntervalId);
  }, [invoice.updatedAt]);

  return (
    <ButtonBase sx={{ textAlign: 'left' }} onClick={onClick}>
      <OrderCard
        sx={{ pt: '20px' }}
        type={type}
        clickable="true"
        invoice={invoice}
        topRightComponent={
          invoice.order && invoice.order.orderStatuses[0].status === 103 ? (
            <Chip
              label={timeElapsed}
              sx={{
                typography: 'body2',
                color: 'error.light',
                bgcolor: 'grey.300',
                mt: '8px'
              }}
            />
          ) : parseInt(timeElapsed.split(':')[0]) > 0 ||
            parseInt(timeElapsed.split(':')[1]) >=
              parseInt(process.env.REACT_APP_TIME_ELAPSED_LIMIT_IN_MINUTE) ? (
            <Chip
              label={timeElapsed}
              sx={{
                typography: 'body2',
                bgcolor: 'error.light',
                color: 'common.white',
                mt: '8px'
              }}
            />
          ) : (
            <Chip label={timeElapsed} sx={{ typography: 'body2', color: 'info.dark' }} />
          )
        }
        bottomComponent={bottomComponent}
      />
    </ButtonBase>
  );
};

const OrderCardWithStopwatchAndChefName = ({ invoice, onClick }) => (
  <OrderCardWithStopwatch
    onClick={onClick}
    invoice={invoice}
    bottomComponent={
      invoice.order && invoice.order.orderStatuses[0].status === 103 ? (
        <Chip
          label={invoice.orderStatus ? invoice.orderStatus.staff.name : invoice.staff.name}
          sx={{
            mt: 2,
            typography: 'body2',
            bgcolor: 'grey.300',
            color: 'error.light'
          }}
        />
      ) : (
        <Chip
          label={invoice.orderStatus ? invoice.orderStatus.staff.name : invoice.staff.name}
          sx={{ mt: 2, typography: 'body2' }}
        />
      )
    }
  />
);

const PhaseColumn = ({ children, name, orderCount }) => (
  <Grid item xs={3} sx={{ maxHeight: 1, display: 'flex', flexDirection: 'column' }}>
    <Paper sx={{ p: 3 }}>
      <Stack
        direction="row"
        spacing={1}
        sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="body1">{name}</Typography>
        <Chip label={orderCount} sx={{ typography: 'body1' }} />
      </Stack>
    </Paper>
    <Stack spacing={2} sx={{ mt: 5, overflow: 'auto', pb: 4.5 }}>
      {children}
    </Stack>
  </Grid>
);

// pending
const PendingPaymentPhaseColumn = ({ invoices, fetchUlang }) => (
  <PhaseColumn name="Pending Payment" orderCount={invoices.length}>
    {invoices.map((invoice) => (
      <OrderCardWithMenu
        invoice={invoice}
        key={invoice.invoiceId}
        type="pending"
        fetchUlang={fetchUlang}
      />
    ))}
  </PhaseColumn>
);

// waitingsss
const WaitingPhaseColumn = ({ invoices, isLoading, setIsLoading, fetchUlang, allLoading }) => (
  <PhaseColumn name="Waiting" orderCount={invoices.length}>
    {!allLoading &&
      invoices.map((invoice, index) => {
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [availableCooks, setAvailableCooks] = useState([]);
        const [cookAssignments, setCookAssignments] = useState([]);

        const handleModalOpen = () => setIsModalOpen(true);
        const handleModalClose = () => setIsModalOpen(false);
        const handleCookChange = (event, orderId) => {
          setCookAssignments((value) =>
            value.map((cookAssignment) =>
              cookAssignment.orderId === orderId
                ? { orderId, staffId: event.target.value }
                : cookAssignment
            )
          );
        };
        const handleAssignClick = async () => {
          const filteredCookAssignments = cookAssignments.filter(
            (cookAssignment) => cookAssignment.staffId
          );

          if (filteredCookAssignments.length === 0) {
            setIsModalOpen(false);
            return;
          }

          try {
            setIsLoading(true);
            await assignCooks(filteredCookAssignments);
            setIsModalOpen(false);

            // location.reload();
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
            fetchUlang();
          }
        };

        useEffect(() => {
          const bootstrapAsync = async () => {
            try {
              const { data } = await getAvailableCooks(invoice.station.id);
              setAvailableCooks(data);
            } catch (error) {
              console.error(error);
            }
          };

          if (isModalOpen) {
            setCookAssignments(
              invoice.orders.map((order) => ({
                orderId: order.id,
                staffId: null
              }))
            );

            bootstrapAsync();
          }
        }, [invoice.orders, invoice.station.id, isModalOpen]);

        return (
          <Fragment key={index}>
            <OrderCardWithStopwatch invoice={invoice} onClick={handleModalOpen} type="waiting" />
            <Dialog
              onClose={handleModalClose}
              open={isModalOpen}
              maxWidth={false}
              PaperProps={{
                sx: { minHeight: '32rem', width: '100%' }
              }}>
              <DialogTitle variant="h5" sx={{ textAlign: 'center', py: 4, fontWeight: 'bold' }}>
                Waiting Invoice
              </DialogTitle>
              <DialogContent
                sx={{
                  p: 0,
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                <Box sx={{ bgcolor: 'grey.50', px: 4, py: 1 }}>
                  <Typography>{invoice.station.name}</Typography>
                </Box>
                <Grid container sx={{ px: 4, mt: 3.25, pb: 3 }}>
                  <Grid container item spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2">Name</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="body2">Qty</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="body2">Notes</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body2">Chef/Barista</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container sx={{ px: 4, pb: 3, overflow: 'auto' }}>
                  {invoice.orders.map((order) => {
                    const foundCookAssignment = cookAssignments.find(
                      (cookAssignment) => cookAssignment.orderId === order.id
                    );

                    return (
                      <Grid
                        key={order.id}
                        container
                        item
                        sx={{ alignItems: 'center', mt: 3 }}
                        spacing={1}>
                        <Grid item xs={3}>
                          <Typography variant="body2">{order.product.name}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography variant="body2">{order.quantity}</Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography variant="body2">{order.note}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <FormControl fullWidth>
                            <Select
                              value={
                                foundCookAssignment
                                  ? foundCookAssignment.staffId
                                    ? foundCookAssignment.staffId
                                    : ''
                                  : ''
                              }
                              onChange={(event) => handleCookChange(event, order.id)}
                              displayEmpty>
                              <MenuItem value="">
                                <em>Choose one</em>
                              </MenuItem>
                              {availableCooks.map((availableCook) => (
                                <MenuItem value={availableCook.staffId} key={availableCook.staffId}>
                                  {availableCook.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </DialogContent>
              <Divider />
              <DialogActions sx={{ p: 3.25 }}>
                <Button onClick={handleAssignClick} variant="contained" disabled={isLoading}>
                  Assign
                </Button>
              </DialogActions>
            </Dialog>
          </Fragment>
        );
      })}
  </PhaseColumn>
);

// ongoing
const OngoingPhaseColumn = ({ invoices, fetchUlang, allLoading }) => (
  <PhaseColumn name="Ongoing" orderCount={invoices.length}>
    {!allLoading && (
      <>
        {invoices.map((invoice) => {
          const [isModalOpen, setIsModalOpen] = useState(false);
          const [qty, setQty] = useState(1);

          const handleModalOpen = () => setIsModalOpen(true);
          const handleModalClose = () => setIsModalOpen(false);
          const handleQtyChange = (event) => setQty(event.target.value);
          const handleApproveClick = async () => {
            try {
              await setStatusCooked(
                invoice.order.orderStatuses.slice(0, qty).map((orderStatus) => orderStatus.id)
              );
              setIsModalOpen(false);
              // location.reload();
            } catch (error) {
              console.error(error);
            } finally {
              fetchUlang();
              // getOverviewBasic().then((res) => console.log(res.data));
              // setKitchenFlow(null);
            }
          };
          const handleDeclineClick = async () => {
            try {
              await ongoingRecook(
                invoice.order.orderStatuses.slice(0, qty).map((orderStatus) => orderStatus.id)
              );
              setIsModalOpen(false);
              // location.reload();
            } catch (error) {
              console.error(error);
            } finally {
              fetchUlang();
            }
          };

          return (
            <Fragment key={invoice.order.orderStatuses[0].id}>
              <OrderCardWithStopwatchAndChefName invoice={invoice} onClick={handleModalOpen} />
              <Dialog
                onClose={handleModalClose}
                open={isModalOpen}
                maxWidth={false}
                PaperProps={{ sx: { width: '100%' } }}>
                <DialogTitle variant="h5" sx={{ textAlign: 'center', py: 4, fontWeight: 'bold' }}>
                  Ongoing Orders
                </DialogTitle>
                <DialogContent
                  sx={{
                    p: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden'
                  }}>
                  <Box sx={{ bgcolor: 'grey.50', px: 4, py: 1 }}>
                    <Typography>{invoice.station.name}</Typography>
                  </Box>
                  <Box sx={{ overflow: 'auto', p: 4 }}>
                    <Grid container direction="column" spacing={2.5}>
                      <Grid container item sx={{ alignItems: 'center' }}>
                        <Grid item xs={4}>
                          Name
                        </Grid>
                        <Grid item xs={8}>
                          {invoice.order.product.name}
                        </Grid>
                      </Grid>
                      <Grid container item sx={{ alignItems: 'center' }}>
                        <Grid item xs={4}>
                          Qty
                        </Grid>
                        <Grid item xs={8}>
                          {invoice.order.orderStatuses.length}
                        </Grid>
                      </Grid>
                      <Grid container item sx={{ alignItems: 'center' }}>
                        <Grid item xs={4}>
                          Notes
                        </Grid>
                        <Grid item xs={8}>
                          {invoice.order.note}
                        </Grid>
                      </Grid>
                      <Grid container item sx={{ alignItems: 'center' }}>
                        <Grid item xs={4}>
                          Chef
                        </Grid>
                        <Grid item xs={8}>
                          {invoice.staff.name}
                        </Grid>
                      </Grid>
                      <Grid container item sx={{ alignItems: 'center' }}>
                        <Grid item xs={4}>
                          Approve/Decline Qty
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            variant="outlined"
                            type="number"
                            value={qty}
                            onChange={handleQtyChange}
                            inputProps={{
                              min: 1,
                              max: invoice.order.orderStatuses.length
                            }}
                            sx={{ width: '6rem' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </DialogContent>
                <Divider />
                <DialogActions sx={{ p: 3.25, justifyContent: 'center' }}>
                  {/* this button is not used, so it is get comment */}
                  {/* <Button onClick={handleDeclineClick} color="error" variant="contained">
                    Decline
                  </Button> */}
                  <Button onClick={handleApproveClick} variant="contained">
                    Approve
                  </Button>
                </DialogActions>
              </Dialog>
            </Fragment>
          );
        })}
      </>
    )}
  </PhaseColumn>
);

// finish
const FinishPhaseColumn = ({ invoices, allLoading, fetchUlang }) => (
  <PhaseColumn name="Finish" orderCount={invoices.length}>
    {!allLoading && (
      <>
        {invoices.map((invoice) => {
          const [isModalOpen, setIsModalOpen] = useState(false);
          const [availableCooks, setAvailableCooks] = useState([]);
          const [selectedCookId, setSelectedCookId] = useState('');
          const [products, setProducts] = useState([]);
          const [reason, setReason] = useState('');
          const [product, setProduct] = useState();
          const [qty, setQty] = useState(1);

          const handleModalOpen = () => setIsModalOpen(true);
          const handleModalClose = () => setIsModalOpen(false);
          const handleCookChange = (event) => setSelectedCookId(event.target.value);
          const handleReasonChange = (event) => setReason(event.target.value);
          const handleQtyChange = (event) => setQty(event.target.value);
          const handleRecookClick = async () => {
            try {
              await onFinishRecook({
                orderStatusIds: invoice.order.orderStatuses
                  .slice(0, qty)
                  .map((orderStatus) => orderStatus.id),
                staffId: selectedCookId,
                productId: product.value,
                reason
              });
              setIsModalOpen(false);
            } catch (error) {
              console.error(error);
            } finally {
              fetchUlang();
            }
          };
          const fetchProductChange = async (keyword = null) => {
            try {
              let { data } = await getProductChange({
                keyword
              });

              let mapped = data.map((product) => ({
                label: product.name,
                value: product.id
              }));

              setProducts(mapped);
              return mapped;
            } catch (error) {
              console.error(error);
            }
          };

          const customStyles = {
            control: (provided, state) => ({
              ...provided,
              background: '#fff',
              borderColor: '#9e9e9e',
              minHeight: '60px',
              height: '60px',
              borderRadius: '10px',
              boxShadow: state.isFocused ? null : null
            }),

            valueContainer: (provided, state) => ({
              ...provided,
              height: '60px',
              padding: '0 12px',
              borderRadius: '10px'
            }),

            input: (provided, state) => ({
              ...provided,
              margin: '0px',
              borderRadius: '10px'
            }),
            indicatorSeparator: (state) => ({
              display: 'none'
            }),
            indicatorsContainer: (provided, state) => ({
              ...provided,
              height: '60px',
              borderRadius: '10px'
            })
          };

          useEffect(() => {
            const bootstrapAsync = async () => {
              try {
                const { data } = await getAvailableCooks(invoice.station.id);
                setAvailableCooks(data);
              } catch (error) {
                console.error(error);
              }
            };

            if (isModalOpen) bootstrapAsync();
          }, [invoice.station.id, isModalOpen]);

          return (
            <Fragment key={invoice.order.orderStatuses[0].id}>
              <OrderCardWithStopwatchAndChefName invoice={invoice} onClick={handleModalOpen} />
              <Dialog
                onClose={handleModalClose}
                open={isModalOpen}
                maxWidth={false}
                PaperProps={{
                  sx: { width: '100%' }
                }}>
                <DialogTitle variant="h5" sx={{ textAlign: 'center', py: 4, fontWeight: 'bold' }}>
                  On Finish Tray Order
                </DialogTitle>
                <DialogContent
                  sx={{
                    p: 0,
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                  <Box sx={{ bgcolor: 'grey.50', px: 4, py: 1 }}>
                    <Typography>{invoice.station.name}</Typography>
                  </Box>
                  <Box sx={{ overflow: 'auto', p: 4 }}>
                    <Grid container direction="column" spacing={2.5}>
                      <Grid container item sx={{ alignItems: 'center' }}>
                        <Grid item xs={4}>
                          Name
                        </Grid>
                        <Grid item xs={8}>
                          {invoice.order.product.name}
                        </Grid>
                      </Grid>
                      <Grid container item sx={{ alignItems: 'center' }}>
                        <Grid item xs={4}>
                          Qty
                        </Grid>
                        <Grid item xs={8}>
                          {invoice.order.orderStatuses.length}
                        </Grid>
                      </Grid>
                      <Grid container item sx={{ alignItems: 'center' }}>
                        <Grid item xs={4}>
                          Notes
                        </Grid>
                        <Grid item xs={8}>
                          {invoice.order.note}
                        </Grid>
                      </Grid>
                      <Grid container item sx={{ alignItems: 'center' }}>
                        <Grid item xs={4}>
                          Chef
                        </Grid>
                        <Grid item xs={8}>
                          {invoice.staff.name}
                        </Grid>
                      </Grid>
                      <Grid container item sx={{ alignItems: 'center' }}>
                        <Grid item xs={4}>
                          Recook Qty
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            variant="outlined"
                            type="number"
                            inputProps={{
                              min: 1,
                              max: invoice.order.orderStatuses.length
                            }}
                            onChange={handleQtyChange}
                            value={qty}
                            sx={{ width: '6rem' }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item sx={{ alignItems: 'center' }}>
                        <Grid item xs={4}>
                          Recook From
                        </Grid>
                        <Grid item xs={8}>
                          <CustomSelectSearch
                            fullWidth
                            customStyles={customStyles}
                            placeholder="Select Product Wrong Into"
                            loadOptions={(keyword) => fetchProductChange(keyword)}
                            value={product}
                            onChange={async (product) => {
                              setProduct(product);
                            }}
                            defaultOptions={products}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item sx={{ alignItems: 'center' }}>
                        <Grid item xs={4}>
                          Chef/Barista
                        </Grid>
                        <Grid item xs={8}>
                          <FormControl fullWidth>
                            <Select value={selectedCookId} onChange={handleCookChange} displayEmpty>
                              <MenuItem value="">
                                <em>Choose one</em>
                              </MenuItem>
                              {availableCooks.map((availableCook) => (
                                <MenuItem value={availableCook.staffId} key={availableCook.staffId}>
                                  {availableCook.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container item sx={{ alignItems: 'center' }}>
                        <Grid item xs={4}>
                          Reason
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            variant="outlined"
                            sx={{ width: 1 }}
                            onChange={handleReasonChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </DialogContent>
                <Divider />
                <DialogActions sx={{ p: 3.25, justifyContent: 'center' }}>
                  <Button onClick={handleRecookClick} variant="contained">
                    Recook
                  </Button>
                </DialogActions>
              </Dialog>
            </Fragment>
          );
        })}
      </>
    )}
  </PhaseColumn>
);

const KitchenFlowGrid = ({ newOrder }) => {
  const [kitchenFlow, setKitchenFlow] = useState(null);
  const [kitchenFlowMoment, setKitchenFlowMoment] = useState(null);
  const [justStart, setJustStart] = useState(true);
  const [allLoading, setAllLoading] = useState(false);

  const [isLoadingWaiting, setIsLoadingWaiting] = useState(false);

  useEffect(() => {
    const controller = new AbortController();

    const bootstrapAsync = async () => {
      setAllLoading(true);
      try {
        const { data } = await getOverview();
        setKitchenFlow(data);
      } catch (error) {
      } finally {
        setAllLoading(false);
        setKitchenFlowMoment(kitchenFlow);
      }
    };

    bootstrapAsync();

    return () => controller.abort();
  }, [newOrder]);

  useEffect(() => {
    if (kitchenFlow) {
      setJustStart(false);
    }
  }, []);

  const fetchUlang = async () => {
    setAllLoading(true);
    try {
      const { data } = await getOverviewBasic();
      setKitchenFlow(data);
    } catch (error) {
      console.error(error);
    } finally {
      setAllLoading(false);
      setKitchenFlowMoment(kitchenFlow);
    }
  };

  return (
    <>
      <Grid container spacing={5} sx={{ overflow: 'hidden' }}>
        <PendingPaymentPhaseColumn
          invoices={
            kitchenFlow
              ? kitchenFlow.pendingList
              : kitchenFlowMoment
              ? kitchenFlowMoment.pendingList
              : []
          }
          fetchUlang={fetchUlang}
        />
        <WaitingPhaseColumn
          invoices={
            kitchenFlow
              ? kitchenFlow.waitingList
              : kitchenFlowMoment
              ? kitchenFlowMoment.waitingList
              : []
          }
          isLoading={isLoadingWaiting}
          setIsLoading={setIsLoadingWaiting}
          fetchUlang={fetchUlang}
          allLoading={allLoading}
        />
        <OngoingPhaseColumn
          invoices={
            kitchenFlow
              ? kitchenFlow.ongoingList
              : kitchenFlowMoment
              ? kitchenFlowMoment.ongoingList
              : []
          }
          fetchUlang={fetchUlang}
          allLoading={allLoading}
        />
        <FinishPhaseColumn
          invoices={
            kitchenFlow
              ? kitchenFlow.finishList
              : kitchenFlowMoment
              ? kitchenFlowMoment.finishList
              : []
          }
          allLoading={allLoading}
          fetchUlang={fetchUlang}
        />
      </Grid>
      {allLoading && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100vh',
            zIndex: '4',
            bgcolor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <CircularProgress />
          <Typography sx={{ ml: '20px' }}>Loading...</Typography>
        </Box>
      )}
    </>
  );
};

const Overview = ({ isNavigationDrawerOpen, onMenuButtonClick, newOrder }) => {
  const role = localStorage.getItem('role');
  const navigate = useNavigate();
  useEffect(() => {
    if (role === 'Reservation') {
      navigate('/reservation');
    }
  }, [role]);
  return (
    <>
      <HeaderBar
        isNavigationDrawerOpen={isNavigationDrawerOpen}
        onMenuButtonClick={onMenuButtonClick}
        title="Overview"
      />
      <Box
        component="main"
        sx={{
          p: 4.5,
          pb: 0,
          maxHeight: '100vh',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column'
        }}>
        <DrawerHeader />
        <KitchenFlowGrid newOrder={newOrder} />
      </Box>
    </>
  );
};

export default Overview;
