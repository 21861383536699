import { kitchenAxios } from '../utils/api';

export const getCooks = () => kitchenAxios.get('cook-display/all-cooks');

export const addCook = (staffId) =>
  kitchenAxios.post('cook-display/add-cook', { staffId });

export const getCookDisplay = () => kitchenAxios.get('cook-display');

export const removeCook = (staffId) =>
  kitchenAxios.post('cook-display/remove-cook', { staffId });
