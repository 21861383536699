import { useState, useContext, useEffect } from 'react';

import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  OutlinedInput,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../contexts/AuthContext';

const Login = () => {
  const { logIn, checkAccessToken, accessToken, admin } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        await checkAccessToken();
        navigate('/overview', { replace: true });
      } catch (error) {
        console.error(error);
      }
    };

    if (accessToken && !admin) bootstrapAsync();
  }, [accessToken, checkAccessToken, navigate, admin]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      await logIn({ emailAddress, password });
      navigate('/overview', { replace: true });
    } catch (error) {
      console.error(error);
    }
  };
  const handleEmailAddressChange = (event) => setEmailAddress(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);

  return (
    <Grid container sx={{ height: '100vh', overflow: 'hidden' }}>
      <Grid
        item
        xs={4}
        sx={{
          bgcolor: 'common.white',
          px: 6.25,
          display: 'flex',
          alignItems: 'center'
        }}>
        <Box component="form" sx={{ flex: 1 }} onSubmit={handleFormSubmit}>
          <Stack>
            <Typography variant="h4" sx={{ textAlign: 'center', fontWeight: 600 }}>
              Enak Kitchen
            </Typography>
            <FormControl variant="standard" sx={{ mt: 5 }}>
              <InputLabel
                htmlFor="email-input"
                shrink
                sx={{ color: 'common.black', transform: 'none' }}>
                Email Address
              </InputLabel>
              <OutlinedInput
                id="email-input"
                variant="outlined"
                type="email"
                sx={{
                  bgcolor: 'grey.200',
                  mt: 3.5
                }}
                inputProps={{
                  sx: {
                    typography: 'body2'
                  }
                }}
                value={emailAddress}
                onChange={handleEmailAddressChange}
              />
            </FormControl>
            <FormControl variant="standard" sx={{ mt: 2.5 }}>
              <InputLabel
                htmlFor="password-input"
                shrink
                sx={{ color: 'common.black', transform: 'none' }}>
                Password
              </InputLabel>
              <OutlinedInput
                id="password-input"
                type={isPasswordShown ? 'text' : 'password'}
                sx={{
                  bgcolor: 'grey.200',
                  mt: 3.5
                }}
                inputProps={{
                  sx: {
                    typography: 'body2'
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setIsPasswordShown((value) => !value)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end">
                      {isPasswordShown ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                }
                value={password}
                onChange={handlePasswordChange}
              />
            </FormControl>
            <Button
              variant="contained"
              disableElevation
              sx={{ py: 1.75, fontSize: '1rem', mt: 5 }}
              type="submit">
              Log in
            </Button>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Box
          component="img"
          src="https://www.featureflooring.com/wp-content/uploads/2020/09/Whats-the-Best-Flooring-for-a-Restaurant-Kitchen.jpg"
          sx={{ width: 1, height: 1, objectFit: 'cover' }}
        />
      </Grid>
    </Grid>
  );
};

export default Login;
