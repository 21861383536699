import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { HeaderBar } from './shared';
import CachedIcon from '@mui/icons-material/Cached';
import debounce from 'lodash.debounce';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { getCancelRequests, updateCancelRequest } from '../api/cancelRequest';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const CancelRequest = ({ isNavigationDrawerOpen, onMenuButtonClick, admin }) => {
  const [cancelRequests, setCancelRequests] = useState([]);
  const [isHandleLoading, setIsHandleLoading] = useState(false);
  const [form, setForm] = useState(null);
  const [dialog, setDialog] = useState({
    description: '',
    button: '',
    color: ''
  });

  const [alert, setAlert] = useState({
    severity: 'success',
    message: '',
    open: false
  });

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [datePicker, setDatePicker] = useState(dayjs());

  const handleChangeStatusCancelRequest = () => {
    const changeCancelRequest = async () => {
      if (form !== null) {
        const payload = {
          status: form?.status
        };
        try {
          await updateCancelRequest(form?.id, payload);
          setAlert({
            message: 'Action success!',
            severity: 'success',
            open: true
          });
        } catch (error) {
          console.error(error);
        } finally {
          fetchCancelRequestsApi();
        }
      } else
        setAlert({
          message: 'Process failed!',
          severity: 'error',
          open: true
        });
    };
    changeCancelRequest();
    doActivity();
    setIsOpenDialog(false);
  };

  const handleButtonAction = (id, status) => {
    const description = [
      {
        description: 'Are you sure to decline this cancel request?',
        button: 'Decline',
        color: 'warning'
      },
      {
        description: 'Are you sure to approve this cancel request?',
        button: 'Approve',
        color: 'primary'
      }
    ];
    setForm({ id, status });
    setDialog(description[status]);
    setIsOpenDialog(true);
  };

  const fetchCancelRequestsApi = async () => {
    setIsHandleLoading(true);
    try {
      const { data } = await getCancelRequests(datePicker.format('YYYY-MM-DD'));
      const cancelRequests = data.invoiceCancelRequests;
      setCancelRequests(cancelRequests);
    } catch (error) {
      console.error();
    } finally {
      setIsHandleLoading(false);
    }
  };

  useEffect(() => {
    fetchCancelRequestsApi();
  }, [datePicker]);

  // !---------------- Activity ---------------------------

  const [isAnyActivity, setIsAnyActivity] = useState(false);

  const activityDebounce = useRef(
    debounce(() => {
      setIsAnyActivity(true);
    }, 5000)
  ).current;

  const doActivity = () => {
    setIsAnyActivity(false);
    activityDebounce();
  };

  useEffect(() => {
    doActivity();
  }, []);

  useEffect(() => {
    if (isAnyActivity) {
      const interval = setInterval(() => {
        fetchCancelRequestsApi();
      }, 90000);

      return () => clearInterval(interval);
    }
  }, [isAnyActivity]);

  return (
    <>
      <HeaderBar
        isNavigationDrawerOpen={isNavigationDrawerOpen}
        onMenuButtonClick={onMenuButtonClick}
        title={`Cancel Request`}
      />

      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={alert.open}>
        <Alert
          severity={alert.severity}
          onClose={() =>
            setAlert({
              message: '',
              open: false,
              severity: 'success'
            })
          }
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={alert.open}>
          {alert.message}
        </Alert>
      </Snackbar>

      <Dialog
        open={isOpenDialog}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={() => {
          setIsOpenDialog(false);
        }}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          <Box sx={{ display: 'flex' }}>
            <Typography>{dialog?.description}</Typography>
            <Button
              color={dialog && dialog?.color}
              sx={{ ml: 'auto' }}
              variant="contained"
              onClick={handleChangeStatusCancelRequest}>
              {dialog?.button}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Box sx={{ mt: '40px', width: '100%', p: '40px', position: 'relative' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button
            sx={{ mb: '10px' }}
            onClick={() => {
              fetchCancelRequestsApi();
              doActivity();
            }}>
            <CachedIcon />
          </Button>
          <Box sx={{ marginLeft: 'auto', marginRight: '10px' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={datePicker}
                sx={{
                  background: '#F7F7F7',
                  borderRadius: '8px',
                  paddingLeft: '4px',
                  width: '140px'
                }}
                format="DD/MMM/YY"
                slotProps={{ textField: { variant: 'standard' } }}
                onChange={(value) => setDatePicker(value)}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ bgcolor: '#363740', color: 'white' }} aria-label="sticky table">
              <TableRow>
                <TableCell sx={{ color: 'white', width: '54px' }}>No.</TableCell>
                <TableCell sx={{ color: 'white' }}>Invoice</TableCell>
                <TableCell sx={{ color: 'white' }}>Name</TableCell>
                <TableCell sx={{ color: 'white' }}>Reason</TableCell>
                <TableCell sx={{ color: 'white' }}>Request By</TableCell>
                <TableCell sx={{ color: 'white' }}>Date</TableCell>
                <TableCell sx={{ color: 'white' }}>Processed By</TableCell>
                <TableCell sx={{ color: 'white' }}>Processed At</TableCell>
                <TableCell sx={{ color: 'white', textAlign: 'center' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cancelRequests.map((cancelRequest, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ width: '54px' }}>{index + 1}</TableCell>
                  <TableCell>{cancelRequest?.invoiceId}</TableCell>
                  <TableCell>{cancelRequest?.name}</TableCell>
                  <TableCell>{cancelRequest?.reason}</TableCell>
                  <TableCell>{cancelRequest?.administrator?.name}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <Typography>{dayjs(cancelRequest?.createdAt).format('DD/MMM/YY')}</Typography>
                      <Typography sx={{ color: 'blue' }}>
                        {dayjs(cancelRequest?.createdAt).format('HH:mm')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{cancelRequest?.processedBy?.name ?? '-'}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <Typography>
                        {cancelRequest?.processedAt
                          ? dayjs(cancelRequest?.processedAt).format('DD/MMM/YY')
                          : '-'}
                      </Typography>
                      <Typography sx={{ color: 'blue' }}>
                        {cancelRequest?.processedAt &&
                          dayjs(cancelRequest?.processedAt).format('HH:mm')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '10px',
                        width: '100%',
                        justifyContent: 'center'
                      }}>
                      {admin.permissions.includes('approve-cancel-order') &&
                      cancelRequest.status === null ? (
                        <>
                          <Button
                            onClick={() => handleButtonAction(cancelRequest?.id, 1)}
                            sx={{ background: '#f4f4f4' }}>
                            <CheckCircle style={{ color: 'green' }} />
                          </Button>
                          <Button
                            onClick={() => handleButtonAction(cancelRequest?.id, 0)}
                            sx={{ background: '#f4f4f4' }}>
                            <Cancel style={{ color: 'red' }} />
                          </Button>
                        </>
                      ) : (
                        '-'
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {isHandleLoading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: 'white',
            opacity: 0.5
          }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};
export default CancelRequest;
