/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { getAllTables, getTableDetail, answerCall, setTableFree } from '../api/tableManagement';
import { DrawerHeader, HeaderBar } from './shared';
import dayjs from 'dayjs';
import useSocketIO from '../hooks/useSocketIO';

const TableDialog = ({ table, open, onClose }) => {
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const { data } = await getTableDetail(table.id);
        setInvoices(data);
      } catch (error) {
        console.error(error);
      }
    };

    if (open) {
      bootstrapAsync();
    }
  }, [open, table.id]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth={false}
      PaperProps={{
        sx: { minHeight: '32rem', width: '100%' }
      }}>
      <DialogTitle variant="h5" sx={{ textAlign: 'center', py: 3, fontWeight: 'bold' }}>
        {table.name}
      </DialogTitle>
      <DialogContent
        sx={{
          p: 0
        }}>
        <Grid container direction="column">
          <Grid
            item
            container
            columnSpacing={1}
            sx={{
              pb: 2,
              px: 4,
              position: 'sticky',
              top: 0,
              bgcolor: 'common.white'
            }}>
            <Grid item xs={3}>
              <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                Name
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                Qty
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                Notes
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                Status
              </Typography>
            </Grid>
          </Grid>
          {invoices.map((invoice) => (
            <Fragment key={invoice.invoiceId}>
              <Grid
                item
                sx={{
                  bgcolor: 'grey.50',
                  px: 4,
                  py: 1
                }}>
                <Stack direction="row" sx={{ alignItems: 'center' }} spacing={1}>
                  <Typography>
                    {new Date(invoice.createdAt).toLocaleTimeString([], {
                      hour12: false
                    })}
                  </Typography>
                  <Divider orientation="vertical" flexItem />
                  <Typography>INV-{invoice.invoiceId}</Typography>
                  {invoice.invoiceStatus && invoice.invoiceStatus === 100 && (
                    <Chip label="Pending Payment" />
                  )}
                </Stack>
              </Grid>
              {invoice.invoiceOrders.map((invoiceOrder, index, invoiceOrders) => (
                <Grid
                  key={invoiceOrder.id}
                  item
                  container
                  sx={[{ px: 4, pt: 3 }, index + 1 === invoiceOrders.length && { pb: 3 }]}
                  columnSpacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body2">{invoiceOrder.product.name}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="body2">{invoiceOrder.quantity}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {invoiceOrder !== null ? (
                      <Typography variant="body2">{invoiceOrder.note}</Typography>
                    ) : (
                      <Typography variant="body2">-</Typography>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {invoiceOrder.statuses.length === 0 ? (
                      <Typography variant="body2">-</Typography>
                    ) : (
                      <>
                        {invoiceOrder.statuses.map((status, index) => (
                          <Typography variant="body2" key={index}>
                            {status.quantity}x {status.name}
                          </Typography>
                        ))}
                      </>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Fragment>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

// declarasi table
const TableCard = ({ table }) => {
  const { triggeredFetch, setTriggeredFetch } = useSocketIO();

  const [isTableDialogOpen, setIsTableDialogOpen] = useState(false);

  const handleDialogOpen = () => setIsTableDialogOpen(true);
  const handleDialogClose = () => setIsTableDialogOpen(false);
  const handleCallingForWaitressClick = async () => {
    try {
      await answerCall(table.id);
      location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClearUpClick = async () => {
    try {
      await setTableFree(table?.id);
    } catch (error) {
      console.error(error);
    } finally {
      setTriggeredFetch((prevState) => prevState + 1);
      // location.reload();
    }
  };

  // timeout -------------
  const clearTableTimeout = (updatedAt) => {
    setTimeout(() => handleClearUpClick(), 3600000 - dayjs().diff(updatedAt, 'milliseconds'));
  };
  // 3600000

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        let invoiceStatuses = table.invoices.map((invoice) => invoice.status);
        let isCleared = !(invoiceStatuses.includes(100) || invoiceStatuses.includes(200));

        if (isCleared) {
          if (dayjs().diff(dayjs(invoiceStatuses[0].updatedAt), 'milliseconds') > 3600000) {
            handleClearUpClick();
          } else {
            clearTableTimeout(dayjs(invoiceStatuses[0].updatedAt));
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    table.occupied && bootstrapAsync();
  }, [triggeredFetch]);

  return (
    <>
      <Paper
        sx={{
          p: 2,
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Typography sx={{ fontSize: '1.875rem' }}>{table.name}</Typography>
        <Box sx={{ flex: 1, mt: 1.5 }}>
          <Typography color="grey.600">{table.occupied === 1 ? 'Occupied' : 'Free'}</Typography>
        </Box>
        {table.occupied === 1 && (
          <Button
            variant="contained"
            fullWidth
            sx={{
              mt: 2.5
            }}
            size="large"
            onClick={handleDialogOpen}>
            Show Orders
          </Button>
        )}
        {table.serverCalledAt !== null ? (
          <Button
            variant="contained"
            fullWidth
            sx={{
              mt: 1.25
            }}
            color="error"
            size="large"
            onClick={handleCallingForWaitressClick}>
            Calling for Waitress
          </Button>
        ) : (
          table.occupied === 1 && (
            <Button
              variant="contained"
              fullWidth
              sx={{
                mt: 1.25
              }}
              size="large"
              color="success"
              onClick={handleClearUpClick}>
              Clear Up
            </Button>
          )
        )}
      </Paper>
      <TableDialog onClose={handleDialogClose} open={isTableDialogOpen} table={table} />
    </>
  );
};

// table list data
const TableList = ({ isNavigationDrawerOpen, onMenuButtonClick }) => {
  const { triggeredFetch } = useSocketIO();

  const [tables, setTables] = useState([]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const { data } = await getAllTables();
        setTables(data);
      } catch (error) {
        console.error(error);
      }
    };

    bootstrapAsync();
  }, [triggeredFetch]);

  return (
    <>
      <HeaderBar
        isNavigationDrawerOpen={isNavigationDrawerOpen}
        onMenuButtonClick={onMenuButtonClick}
        title="Table Management"
      />
      <Box component="main" sx={{ flexGrow: 1, p: 4.5, pt: 0.625 }}>
        <DrawerHeader />
        <Grid container sx={{ mt: -2 }} spacing={4.5}>
          {tables.map((table) => (
            <Grid item xs={3} lg={2.4} key={table.id}>
              <TableCard table={table} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default TableList;
