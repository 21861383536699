export const getCurrentTimeString = () =>
  new Date().toLocaleTimeString([], {
    hour12: false
  });

export const getTimeDifference = (dateTime) =>
  new Date(new Date().getTime() - new Date(dateTime).getTime()).toISOString().slice(11, 19);

export const createCalendarList = (startDate = new Date()) => {
  let nameOfDays = new Array(7);
  nameOfDays[0] = 'Sunday';
  nameOfDays[1] = 'Monday';
  nameOfDays[2] = 'Tuesday';
  nameOfDays[3] = 'Wednesday';
  nameOfDays[4] = 'Thursday';
  nameOfDays[5] = 'Friday';
  nameOfDays[6] = 'Saturday';

  let nameOfMonths = [];
  nameOfMonths[0] = 'January';
  nameOfMonths[1] = 'February';
  nameOfMonths[2] = 'March';
  nameOfMonths[3] = 'April';
  nameOfMonths[4] = 'May';
  nameOfMonths[5] = 'June';
  nameOfMonths[6] = 'July';
  nameOfMonths[7] = 'August';
  nameOfMonths[8] = 'September';
  nameOfMonths[9] = 'October';
  nameOfMonths[10] = 'November';
  nameOfMonths[11] = 'December';

  let tempLists = [];

  for (let j = 0; j <= 30; j++) {
    let tempDate = new Date(new Date().setDate(startDate.getDate() + j));
    let year = tempDate.getFullYear();
    let month = tempDate.getMonth() + 1;
    let day = tempDate.getDate();

    let formatTempDate = `${year}-${month < 10 ? `0${month}` : month}-${
      day < 10 ? `0${day}` : day
    }`;

    tempLists.push({
      date: formatTempDate,
      day,
      dayName: nameOfDays[new Date(tempDate).getDay()],
      monthName: nameOfMonths[new Date(tempDate).getMonth()],
      year
    });
  }

  return tempLists;
};

export const getDetailDate = (initialDate) => {
  const date = new Date(initialDate);

  let month = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;

  let day = date.getDate();
  day = day < 10 ? `0${day}` : day;

  return {
    year: date.getFullYear(),
    month,
    day
  };
};

export const SubstringSecond = (time) => {
  return time.substring(0, 5);
};

export const addOneMonth = (initialDate) => {
  let newDate = new Date(initialDate);

  newDate.setMonth(newDate.getMonth() + 1);

  if (newDate.getDate() !== initialDate.getDate()) {
    newDate.setDate(0);
  }

  return newDate;
};
